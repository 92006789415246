<script>

	import { onMount, onDestroy, setContext, getContext } from 'svelte';
	import { fade } from 'svelte/transition';
	import * as util from '@/util'
	
	const {
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		selected_location_id,
		selected_doctor_id,
		selected_appointment_type_id,
		selected_location,
		selected_doctor,
		selected_appointment_type,
		show_modal,
		root_element,
		component_width,
		loading_slots,
		slot_data,
		all_appointment_types,
		api_working,
		selected_slot_id,
		pagination_index,
		request_appointment_complete,
		form_submissions,
	} = getContext('schedulerStore');

	import Portal from "svelte-portal";
	
	import global_css from '@/assets/css/global.css?raw';

	import dayjs from 'dayjs'
	import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
	const lottie_animation_src = new URL('@/assets/done-animation.json', import.meta.url).href
	import { ics } from 'ics'
	import { createEvent} from 'ics';

	import Slots from '@/lib/Slots.svelte';
	import Form from '@/lib/formbuilder/Form.svelte';
	
	let modal_close_button = null

	let modal_width
		

	$: if ($selected_slot_id) {
		setRequestAppointmentComplete()
	}

	function setRequestAppointmentComplete() {
		$request_appointment_complete = false
	}


	function closeAppointmentModal() {
	
		document.body.classList.remove('scheduler-modal-active')
		
		$show_modal = false

		$root_element.querySelector('[data-slot-id="'+$selected_slot_id+'"]')?.focus()

		$selected_slot_id = null

	}

	async function downloadIcal() {

		let date = $slot_data.slots.find(item => item.slotId == $selected_slot_id)?.slotDateTime

		const event = {
			title: `Appointment with ${$selected_doctor.doctorName}`,
			start: [Number(dayjs(date).format('YYYY')), Number(dayjs(date).format('MM')), Number(dayjs(date).format('DD')), Number(dayjs(date).format('HH')), Number(dayjs(date).format('mm'))],
			duration: { minutes: 60 },
			location: `${$selected_location.addressLine1 || ''} ${$selected_location.addressLine2 || ''} ${$selected_location.city || ''}, ${$selected_location.state || ''}`,
			description: `Phone: ${$selected_location.phone}`
		 }

		const filename = 'Appointment.ics'
		const file = await new Promise((resolve, reject) => {
			createEvent(event, (error, value) => {
				if (error) {
					reject(error)
				}
				resolve(new File([value], filename, { type: 'text/calendar' }))
			})
		})

		const url = URL.createObjectURL(file);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = filename;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
		URL.revokeObjectURL(url);


	}


</script>


<Portal target="body">


	<div class="scheduler-modal"

		class:tablet={modal_width < 800 }
		class:mobile={modal_width <= 520}
		bind:clientWidth={modal_width}

		{...$$restProps}

		>	

		<div class="scheduler-modal__overlay" transition:fade={{ delay: 0, duration: 200 }}></div>

		<div class="scheduler-modal__container" transition:fade={{ duration: 100, delay: 0, opacity: 0, start: 0.9 }}>
		
			<div class="scheduler-modal__content">

				<button type="button" on:focus={() => {modal_close_button.focus()}} aria-label="Back to top"></button>

				<a href="#" class="scheduler-modal__close" aria-label="Close" bind:this={modal_close_button} on:click={() => {
					closeAppointmentModal();
					util.handleAnalyticEvent('gsp_scheduler_modal_exit');
				}}>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>
				</a>
								
				
				{#if $request_appointment_complete}
					
					{@const doctor = $selected_location?.doctors?.find(item => item.doctorOesId == $form_submissions[$form_submissions.length-1].doctor_id)}						
				
					<div class="booking-preview" style="text-align:center">

						<h2 style="margin:0">Success!</h2>

						<div style="width:150px;margin:-20px auto -20px auto">
							<LottiePlayer src={lottie_animation_src} autoplay={true} loop={false} renderer='svg' background='transparent' height={150} width={150} controls={null} controlsLayout={null} />
						</div>
							
						<h2 style="margin:0 0 20px 0;padding:0"> 
							Dr. {doctor.doctorName}
						</h2>		

						<p style="margin:0 0 10px 0;padding:0">
							<svg style="vertical-align:middle;margin:-3px 0 0 0" class="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"/></svg>
							<span>{dayjs($slot_data.slots.find(item => item.slotId == $selected_slot_id)?.slotDateTime).format('h:mm a, ddd. MMM DD, YYYY')}</span>
						</p>
						<p style="margin:0 0 20px 0;padding:0">
							<svg style="vertical-align:middle;margin:-3px 0 0 0" class="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5"/></svg> 
							<span>{$selected_location.addressLine1 || ''} {$selected_location.addressLine2 || ''} {$selected_location.city || ''},	{$selected_location.state || ''}</span>
						</p>
							

						<p style="margin:0 0 20px 0;padding:0">
							{#if $selected_location.isOnlineBookingEnabled}
								<strong>Your appointment has been booked</strong>.
							{:else}
								<strong>Appointment request received. <br>We will confirm shortly.</strong>
							{/if}
						</p>

						{#if $selected_location?.isOnlineBookingEnabled}
							<p style="text-align:center;margin:0 0 20px 0;padding:0">
								<button type="button" class="button button--primary gsp-track" on:click={() => {
									downloadIcal();
									util.handleAnalyticEvent('gsp_calendar_btn_click');
								}}>
									<span class="button__icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M16.53 11.06L15.47 10l-4.88 4.88l-2.12-2.12l-1.06 1.06L10.59 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H5V8h14z"/></svg>
									</span>
									<span class="button__label">
										Add to Calendar
									</span>
								</button>
							</p>
						{/if}

						<p style="margin:0 0 20px 0;padding:0">Add another appointment:</p>

					</div>


					
					<Slots />

				{:else if $selected_doctor}

					{@const doctor = $selected_location?.doctors?.find(item => item.doctorOesId == $selected_doctor_id)}

					<div class="scheduler-modal__header">

						<div class="booking-preview">

							<h2>									 
								Dr. {doctor.doctorName}
							</h2>

							<div class="booking-preview__columns">
							
								<div class="booking-preview__details">								
									<p>
										<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"/></svg>
										<span>{#if $component_width > 480}When: {/if}
										{dayjs($slot_data.slots.find(item => item.slotId == $selected_slot_id)?.slotDateTime).format('h:mm a, ddd. MMM DD, YYYY')}</span>
									</p>
									<p>
										<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5"/></svg> 
										<span>{#if $component_width > 480}Where: {/if}{$selected_location.addressLine1 || ''} {$selected_location.addressLine2 || ''} {$selected_location.city || ''},	{$selected_location.state || ''}</span>
									</p>
									<p> <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m1 17h-2v-2h2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25"/></svg>
										<span>
											{#if $component_width < 480}
												Help?: <a href={'tel:'+$selected_location.phone} style="text-decoration:underline">Call Us</a>
											{:else}
												Help?: Call {$selected_location.phone}
											{/if}
										</span>
									</p>

								</div>

								{#if doctor.doctorPictureUrl}
									<div class="booking-preview__photo">

										<img src={doctor.doctorPictureUrl} alt={'Photograph of '+doctor.doctorName}>								

									</div>
								{/if}

							</div>

						</div>


					</div>

					{#if $selected_location.schedulerNote}
						<div class="scheduler-note">
							{@html $selected_location.schedulerNote.replace(/\r\n|\r|\n/g,'<br />')}
						</div>
					{/if}

					<Form />
					

				{/if}

				
				<div class="scheduler-modal__footer">

					<p>
						
						{#if $component_width < 480}
							Any Questions? <a href={'tel:'+$selected_location.phone}  style="text-decoration:underline">Call Us</a>
						{:else}
							Questions? Call us at {$selected_location.phone}
						{/if}

						<button type="button" on:focus={() => {modal_close_button.focus()}} aria-label="Back to top"></button>
					
					</p>

					
					<!--
					
					{JSON.stringify($submitted_form_data)}
					
					selected_appointment_type_id: {$selected_appointment_type_id}, selected_doctor_id: {$selected_doctor_id}

					-->

					
				</div>
				


			</div>

		</div>

		
		{@html `<style id="modal-css">

			${global_css}

		</style>`}

	</div>

</Portal>