<!-- comment out the following while developing -->

<svelte:options
	customElement={{
		tag: 'gsp-scheduler',
	}}
/>



<script>
	
	import * as util from '@/util'

	import { onMount, onDestroy, setContext, getContext } from 'svelte';
	import { createStore } from '@/store.js';

	import global_css from './assets/css/global.css?raw';
	
	import Slots from '@/lib/Slots.svelte';
	import Modal from '@/lib/Modal.svelte';


	const store = createStore();
	setContext('schedulerStore', store);

	const {
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		show_modal,
		root_element,
		component_width,
		loading_slots,
		selected_doctor_id,
	} = getContext('schedulerStore');



	export let account_id = ''
	if (account_id) $prop_account_id = account_id

	export let location_id = ''
	if (location_id) $prop_location_id = location_id

	export let doctor_id = ''
	if (doctor_id) {
		$prop_doctor_id = doctor_id
		$selected_doctor_id = doctor_id
	}

	export let color_background = '#fff';
	export let color_text = 'inherit';
	export let color_light_border = '#e0e0e0';
	export let color_primary_action = '#024e73';

	const desktop_loading_src = new URL('./assets/skeleton-desktop.svg', import.meta.url).href
	const mobile_loading_src = new URL('./assets/skeleton-mobile.svg', import.meta.url).href

	//$: console.log($slot_data)

/*******************************************************/
/* Get slot data
/*******************************************************/
	
	onMount(() => {
		util.getSlotData(store);
	})


</script>


{@html `<style id="site-css">

	${global_css}

</style>`}


<div class="scheduler" 
	class:scheduler-modal-active={$show_modal}
	bind:this={$root_element}
	class:desktop={$component_width >= 800}
	class:tablet={$component_width < 800 && $component_width > 520}
	class:mobile={$component_width <= 520}
	bind:clientWidth={$component_width}
	style="
		--color_background: {color_background};
		--color_text: {color_text};
		--color_light_border: {color_light_border};
		--color_primary_action: {color_primary_action};
		--success: #04cb58;
		--danger: #e23807;
	">
	

	{#if $loading_slots}
		<div class="loading-animation">
			<img src={desktop_loading_src} alt="Loading..." class="desktop" />
			<img src={mobile_loading_src} alt="Loading..." class="mobile"	/>
		</div>
	{/if}



	{#if ! $loading_slots}

		{#if ! $prop_account_id}
			<div class="error">No Account ID</div>
		{/if}

		<Slots />
		

	{/if}

		
	{#if $show_modal}
		
		<Modal 
			style="
				--color_background: {color_background};
				--color_text: {color_text};
				--color_light_border: {color_light_border};
				--color_primary_action: {color_primary_action};
				--success: #04cb58;
				--danger: #e23807;
			"
		/>

	{/if}


</div>

<!--
<br><br><br><hr>
$selected_slot_id: {$selected_slot_id}, $selected_doctor_id: {$selected_doctor_id}, $selected_doctor name: {$selected_doctor?.doctorName}, $selected_location_id: {$selected_location_id}
-->

