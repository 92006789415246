// createStore.js
import { writable, derived } from 'svelte/store';

export function createStore() {
	const component_width = writable(null);

	const root_element = writable();

	const loading_slots = writable(false);

	const api_working = writable(false);

	const show_modal = writable(false);

	const submitted_form_data = writable({});

	const slot_data = writable({});

	const pagination_index = writable(0);

	const all_appointment_types = writable([]);

	const prop_success_redirect_url = writable();
	const prop_account_id = writable();
	const prop_location_id = writable();
	const prop_doctor_id = writable();

	const selected_slot_id = writable();
	const selected_location_id = writable();
	const selected_doctor_id = writable();
	const selected_appointment_type_id = writable();

	const selected_location = derived(
		[slot_data, selected_location_id],
		([$slot_data, $selected_location_id]) =>
			$slot_data?.locations?.find(item => item.locationSamuraiId == $selected_location_id)
	);

	const selected_doctor = derived(
		[selected_location, selected_doctor_id],
		([$selected_location, $selected_doctor_id]) =>
			$selected_location?.doctors?.find(item => item.doctorOesId == $selected_doctor_id)
	);

	const selected_appointment_type = derived(
		[selected_location, selected_appointment_type_id],
		([$selected_location, $selected_appointment_type_id]) =>
			$selected_location?.appointmentTypes?.find(item => item.appointmentTypeId == $selected_appointment_type_id)
	);

	const request_appointment_complete = writable(false);

	const form_submissions = writable([]);

	const form_schema = writable({});

	return {
		component_width,
		root_element,
		loading_slots,
		api_working,
		show_modal,
		submitted_form_data,
		slot_data,
		pagination_index,
		all_appointment_types,
		prop_success_redirect_url,
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		selected_slot_id,
		selected_location_id,
		selected_doctor_id,
		selected_appointment_type_id,
		selected_location,
		selected_doctor,
		selected_appointment_type,
		request_appointment_complete,
		form_submissions,
		form_schema
	};
}
