import { get } from 'svelte/store';

export async function getSlotData(store) {
	
	const {
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		show_modal,
		root_element,
		component_width,
		loading_slots,
		slot_data,
		all_appointment_types,
		api_working,
		selected_location_id
	} = store;




	//prop_account_id.set(135); //Yesnick
	//prop_account_id.set(3404); //Not Revolution EHR
	//prop_account_id.set(3404); //Simmons
	//prop_account_id.set(1960); // Rev EHR // Elevated Eyecare
	//prop_account_id.set(135); // NON Rev EHR
	//prop_account_id.set(1718); // Seashore
	//prop_account_id.set(3843);
	//prop_account_id.set(4340); //Dr. Mariam Hermiz
	//prop_account_id.set(107)
	//prop_account_id.set(2444)
	//prop_account_id.set(4119) //ehrType": "oop"
	//prop_account_id.set(860) // Foxfire
	//prop_account_id.set(3709)
	//prop_account_id.set(2696) // Towne Lake Eye Associates
	//prop_account_id.set(2060)
	//prop_account_id.set(2332) // https://www.lindsayoptometry.com/contact-us/appointment-request-form/
	//prop_account_id.set(2807) //https://www.premiereyedoctors.com/
	//prop_account_id.set(4450) // https://www.eyesitemeridian.com/contact-us/appointment-request-form/
	//prop_account_id.set(2332) 
	//prop_account_id.set(3360)
	//prop_account_id.set(3709)
	//prop_account_id.set(506)
	

	// Now use `get(store)` instead of $store
	const account_id = get(prop_account_id);
	if (!account_id) {
		loading_slots.set(false);
		return;
	}

	prop_account_id.set(Number(account_id));
	api_working.set(true);

	try {
		const response = await fetch(`${import.meta.env.VITE_FETCH_DOMAIN}/SchedulerWidget/WidgetSlots/${get(prop_account_id)}`);
		const data = await response.json();

		slot_data.set(data);
	} catch (error) {
		console.log('Error fetching data');
		loading_slots.set(false);
		return;
	} finally {
		api_working.set(false);
	}

	const _slot_data = get(slot_data);

	if (!_slot_data?.doctors?.length) {
		loading_slots.set(true);
		console.log('No doctor data');
		return;
	}
	if (!_slot_data?.locations.length) {
		loading_slots.set(false);
		console.log('No location data');
		return;
	}


	if (get(prop_doctor_id)) {

		_slot_data.doctors = _slot_data.doctors.filter(item => item.doctorOesId == get(prop_doctor_id));

		for (let location of _slot_data.locations) {
			location.doctors = location.doctors.filter(item => item.doctorOesId == get(prop_doctor_id));
		}
	}

	for (let location of _slot_data.locations) {
		location.doctors = location.doctors.sort((a, b) => a.order - b.order);
		for (const doctor of location.doctors) {
			for (const slot of doctor.slots) {
				if (!slot.appointmentTypes?.length) {
					slot.appointmentTypes = _slot_data.appointmentTypes;
				}
			}
		}
	}

	if (_slot_data.locations.length === 1) {
		selected_location_id.set(_slot_data.locations[0]?.locationSamuraiId);
	} else if (get(prop_location_id)) {
		selected_location_id.set(get(prop_location_id));
	}

	slot_data.set(_slot_data)

	loading_slots.set(false);
}



export function handleAnalyticEvent(event) {
	//console.log(event)

	window?.dataLayer?.push({
		event: 'gsp_scheduler_ga4_event',
		ga4_event_gsp_scheduler: {
			name: event,
			//originalEvent: event
		}
	});

	if (event == 'gsp_new_patient_clk' || event == 'gsp_returning_patient_clk') {

		let cookieValue = (event == 'gsp_new_patient_clk' ? 1 : 0);
		let cookieName  = "gsp_p"; 
		let cookiePath  = "/";
		let expirationTime = 2628000 * 1000                       //For example one month in seconds (2628000)
		let date = new Date();                                  //Create javascript date object
		let dateTimeNow = date.getTime();                       //Get current time in milliseconds since 1 january 1970 (Unix time)
		date.setTime(dateTimeNow + expirationTime);             //Set expiration time (Time now + one month)
		expirationTime = date.toUTCString();                //Convert milliseconds to UTC time string
		document.cookie = cookieName+"="+cookieValue+"; expires="+expirationTime+"; path="+cookiePath;  //Set cookie

	}
	

}