<script>
	
	// account_id 107
	// Ancaster Family Eye Care

	// Removed ins_id_ssid

	export let selected_location
	export let invalid_fields

	export let schedule_form
	export let required_fields
	export let submitted_data

	// Parent functions
	export let testField
	export let handleAnalyticEvent

	const years = Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (v, i) => new Date().getFullYear() - i);
	const months = [{value: '01', label: '1 - Jan'},{value: '02', label: '2 - Feb'},{value: '03', label: '3 - Mar'},{value: '04', label: '4 - Apr'},{value: '05', label: '5 - May'},{value: '06', label: '6 - Jun'},{value: '07', label: '7 - Jul'},{value: '08', label: '8 - Aug'},{value: '09', label: '9 - Sep'},{value: '10', label: '10 - Oct'},{value: '11', label: '11 - Nov'},{value: '12', label: '12 - Dec'}]
	const days = Array.from({ length: 31 }, (v, i) => String(i + 1).padStart(2, '0'));


	required_fields = [
		'appointment_type',
		'patient_first_name',
		'patient_last_name',
		'patient_email',
		'patient_phone',
		'patient_birthday_year',
		'patient_birthday_month',
		'patient_birthday_day',
		'returning_patient',
	]

	$: if (selected_location?.ehrType == 'oop') {
		
		required_fields.push(
			'health_card_number'
		)

	} else {
		required_fields.push(
			'ins_benefits'
		)
	}


	let ins_benefit_fields = [
		'ins_employer_company',
		'ins_policy_id',
		'ins_holder_name',
		'ins_holder_birthday_year',
		'ins_holder_birthday_month',
		'ins_holder_birthday_day',
	]


	$: {
		required_fields = required_fields.filter(
			(field) => !ins_benefit_fields.includes(field)
		);
		if (schedule_form?.ins_benefits === 'yes') {
			required_fields = [...required_fields, ...ins_benefit_fields];
		}
	}


	
	
	$: if (submitted_data && schedule_form) {

		submitted_data['appointment_slot_id'] = schedule_form.appointment_slot_id
		submitted_data['appointment_type_id'] = schedule_form.appointment_type_id?.toString()
		submitted_data['patient_first_name'] = schedule_form.patient_first_name
		submitted_data['patient_last_name'] = schedule_form.patient_last_name
		submitted_data['patient_email'] = schedule_form.patient_email
		submitted_data['patient_phone'] = schedule_form.patient_phone
		submitted_data['patient_birthday'] = schedule_form.patient_birthday_year+'-'+schedule_form.patient_birthday_month+'-'+schedule_form.patient_birthday_day
		submitted_data['returning_patient'] = schedule_form.returning_patient
		submitted_data['ins_benefits'] = schedule_form.ins_benefits
		submitted_data['health_card_number'] = schedule_form.health_card_number?.toString()	

		if (schedule_form.ins_benefits == 'yes') {
			submitted_data['ins_employer_company'] = schedule_form.ins_employer_company
			submitted_data['ins_policy_id'] =  schedule_form.ins_policy_id?.toString(),
			submitted_data['ins_holder_name'] = schedule_form.ins_holder_name
			submitted_data['ins_holder_birthday'] = schedule_form.ins_holder_birthday_year+'-'+schedule_form.ins_holder_birthday_month+'-'+schedule_form.ins_holder_birthday_day
		} else {
			delete submitted_data['ins_employer_company']
			delete submitted_data['ins_policy_id']
			delete submitted_data['ins_holder_name']
			delete submitted_data['ins_holder_birthday']
		}

	}

	

</script>



<div class="fields-grid fields-grid--2">

	<div class="field field--inset-label">
		<div class="field__label">
			<label for="patient_email">Email</label><span class="field__required" aria-label="Required field">*</span>
		</div>
		<div class="field__content">
			<input type="text" class="field__input field__input--textfield" id="patient_email" name="patient_email" bind:value={schedule_form.patient_email} on:blur={(event) => {testField('patient_email')}}>
		</div>
		{#if invalid_fields['patient_email']}
			<div class="field__required-message">
				{#if ! schedule_form.patient_email}
					<strong>!</strong> - Email required
				{:else}
					<strong>!</strong> - Valid email required
				{/if}
			</div>
		{/if}
		<div class="field__description">
			You consent to receive emails from us, but can click “Unsubscribe” from any email to opt out
		</div>
	</div>


	<div class="field field--inset-label">
		<div class="field__label">
			<label for="patient_phone">Phone</label><span class="field__required" aria-label="Required field">*</span>
		</div>
		<div class="field__content">
			<input type="tel" class="field__input field__input--textfield" id="patient_phone" name="patient_phone" bind:value={schedule_form.patient_phone} on:blur={(event) => {testField('patient_phone')}}>
		</div>
		{#if invalid_fields['patient_phone']}
			<div class="field__required-message"><strong>!</strong> - Valid phone required</div>
		{/if}
		<div class="field__description">
			You consent to receive texts from us, but can reply “STOP” to any text to opt out
		</div>
	</div>

</div>




{#if selected_location?.ehrType == 'oop'}
	<div class="field field--inset-label">
		<div class="field__label">
			<label for="health_card_number">Health Card Number</label><span class="field__required" aria-label="Required field">*</span>
		</div>
		<div class="field__content">
			<input type="text" class="field__input field__input--textfield" id="health_card_number" name="health_card_number" bind:value={schedule_form.health_card_number} on:blur={(event) => {testField('health_card_number')}} minlength="10" maxlength="12">
		</div>
		{#if invalid_fields['health_card_number']}
			<div class="field__required-message">
				{#if ! schedule_form.health_card_number}
					<strong>!</strong> - Health card number required
				{:else}
					<strong>!</strong> - Valid health card number required
				{/if}
			</div>
		{/if}
	</div>
{/if}






<div class="fields-grid fields-grid--2">


	<div>

		<div class="field__label">
			<label for="patient_birthday_day">Date of Birth</label><span class="field__required" aria-label="Required field">*</span>
		</div>

		<div class="fields-grid fields-grid--3 fields-grid--date">

			<div class="field field--select">
				
				<div class="field__content">
					<select class="field__input field__input--select" id="patient_birthday_day" name="patient_birthday_day" bind:value={schedule_form.patient_birthday_day} on:blur={(event) => {testField('patient_birthday_day')}}>
						<option value={null}>Day</option>
						{#each days as day}
							<option value={day}>{day}</option>
						{/each}
					</select>
				</div>
				
			</div>

			<div class="field field--select">
				
				<div class="field__content">
					<select class="field__input field__input--select" id="patient_birthday_month" name="patient_birthday_month" bind:value={schedule_form.patient_birthday_month} on:blur={(event) => {testField('patient_birthday_month')}}>
						<option value={null}>Month</option>
						{#each months as month}
							<option value={month.value}>{month.label}</option>
						{/each}
					</select>
				</div>
				
			</div>

			<div class="field field--select">
				
				<div class="field__content">
					<select class="field__input field__input--select" id="patient_birthday_year" name="patient_birthday_year" bind:value={schedule_form.patient_birthday_year} on:blur={(event) => {testField('patient_birthday_year')}}>
						<option value={null}>Year</option>
						{#each years as year}
							<option value={year}>{year}</option>
						{/each}
					</select>
				</div>
				

			</div>

		</div>

		{#if invalid_fields['patient_birthday_day'] || invalid_fields['patient_birthday_month'] || invalid_fields['patient_birthday_year']}
			<div class="field__required-message" style="margin-top:3px"><strong>!</strong> - Date of birth required</div>
		{/if}

	</div>



	<div class="field">
		<div class="field__label">
			<label>New or Returning Patient</label><span class="field__required" aria-label="Required field">*</span>
		</div>
		<div class="field__content">
			
			<button type="button" class="button button--outline" name="returning_patient" class:button--inactive={schedule_form.returning_patient != 'no'} on:click={() => {
				schedule_form.returning_patient = 'no';
				testField('returning_patient');
				handleAnalyticEvent('gsp_new_patient_clk');
			}}>
				<span class="button__label">New</span>
			</button>
			<button type="button" class="button button--outline" name="returning_patient" class:button--inactive={schedule_form.returning_patient != 'yes'} on:click={() => {
				schedule_form.returning_patient = 'yes';
				testField('returning_patient');
				handleAnalyticEvent('gsp_returning_patient_clk');
			}}>	<span class="button__label">Returning</span>
			</button>
			

		</div>
		{#if invalid_fields['returning_patient']}
			<div class="field__required-message"><strong>!</strong> - New or returning patient is required</div>
		{/if}

		{#if schedule_form.returning_patient == 'no'}
			<div class="zznotice zznotice--success" style="margin-top:10px">
				🎉 &nbsp; Welcome, We Love New Patients!
			</div>
		{/if}
		{#if schedule_form.returning_patient == 'yes'}
			<div class="zznotice zznotice--success" style="margin-top:10px">
				👋 &nbsp; Welcome Back!
			</div>
		{/if}

	</div>



	
	


	{#if selected_location?.ehrType != 'oop'}
		
		<div class="field">
			<div class="field__label">
				<label>
					Insurance Benefits
				</label>

				<span class="field__required" aria-label="Required field">*</span>
			</div>
			<div class="field__content">

				<button type="button" class="button button--outline" class:button--inactive={schedule_form.ins_benefits !== 'yes'} on:click={() => {schedule_form.ins_benefits = 'yes';testField('ins_benefits');handleAnalyticEvent('gsp_ins_benefits_clk');}}>
					<span class="button__label">Yes</span>
				</button>
				<button type="button" class="button button--outline" class:button--inactive={schedule_form.ins_benefits !== 'no'} on:click={() => {schedule_form.ins_benefits = 'no';testField('ins_benefits');handleAnalyticEvent('gsp_ins_benefits_clk');}}>
					<span class="button__label">No</span>
				</button>

			</div>
			{#if invalid_fields['ins_benefits']}
				<div class="field__required-message"><strong>!</strong> - Insurance benefits is required</div>
			{/if}
		</div>

	{/if}

</div>


{#if schedule_form.ins_benefits == 'yes'}

	<div class="insurance">


		<div class="field">
			<div class="field__label">
				<label for="ins_employer_company">					
					Insurance Company / Vision Plan
				</label><span class="field__required" aria-label="Required field">*</span>
			</div>
			<div class="field__content">
				<input type="text" class="field__input field__input--textfield" id="ins_employer_company" name="ins_employer_company" bind:value={schedule_form.ins_employer_company} on:blur={(event) => {testField('ins_employer_company')}}>
			</div>
			{#if invalid_fields['ins_employer_company']}
				<div class="field__required-message"><strong>!</strong> - Insurance company / vision plan is required</div>
			{/if}
		</div>


		<div class="fields-grid fields-grid--2">
		
			<div class="field">
				<div class="field__label">
					<label for="ins_holder_name">
						Policy Holder Name
					</label><span class="field__required" aria-label="Required field">*</span>
				</div>
				<div class="field__content">
					<input type="text" class="field__input field__input--textfield" id="ins_holder_name" name="ins_holder_name" bind:value={schedule_form.ins_holder_name} on:blur={(event) => {testField('ins_holder_name')}}>
				</div>
				{#if invalid_fields['ins_holder_name']}
					<div class="field__required-message"><strong>!</strong> - Policy holder name is required</div>
				{/if}
			</div>


			<div>

				<div class="field__label">
					<label for="ins_holder_birthday_day">
						Policy Holder's Birthdate
					</label><span class="field__required" aria-label="Required field">*</span>
				</div>

				<div class="fields-grid fields-grid--3 fields-grid--date">

					<div class="field field--select">
						
						<div class="field__content">
							<select class="field__input field__input--select" id="ins_holder_birthday_day" name="ins_holder_birthday_day" bind:value={schedule_form.ins_holder_birthday_day} on:blur={(event) => {testField('ins_holder_birthday_day')}}>
								<option value={null}>Day</option>
								{#each days as day}
									<option value={day}>{day}</option>
								{/each}
							</select>
						</div>
					</div>

					<div class="field field--select">
						
						<div class="field__content">
							<select class="field__input field__input--select" id="ins_holder_birthday_month" name="ins_holder_birthday_month" bind:value={schedule_form.ins_holder_birthday_month} on:blur={(event) => {testField('ins_holder_birthday_month')}}>
								<option value={null}>Month</option>
								{#each months as month}
									<option value={month.value}>{month.label}</option>
								{/each}
							</select>
						</div>
					</div>

					<div class="field field--select">
						
						<div class="field__content">
							<select class="field__input field__input--select" id="ins_holder_birthday_year" name="ins_holder_birthday_year" bind:value={schedule_form.ins_holder_birthday_year} on:blur={(event) => {testField('ins_holder_birthday_year')}}>
								<option value={null}>Year</option>
								{#each years as year}
									<option value={year}>{year}</option>
								{/each}
							</select>
						</div>

					</div>

				</div>

				{#if invalid_fields['ins_holder_birthday_day'] || invalid_fields['ins_holder_birthday_month'] || invalid_fields['ins_holder_birthday_year']}
					<div class="field__required-message" style="margin-top:3px"><strong>!</strong> - Policy holder's birthdate is required</div>
				{/if}

			</div>


			
			<div class="field">
				<div class="field__label">
					<label for="ins_policy_id">
						Policy ID Number					
					</label>
					<span class="field__required" aria-label="Required field">*</span>
					
				</div>
				<div class="field__content">
					<input type="number" class="field__input field__input--number" id="ins_policy_id" name="ins_policy_id" bind:value={schedule_form.ins_policy_id} on:blur={(event) => {testField('ins_policy_id')}}>
				</div>
				{#if invalid_fields['ins_policy_id']}
					<div class="field__required-message"><strong>!</strong> - Policy ID number is required</div>
				{/if}
			</div>



		</div>
	

	</div>

{/if}
			
