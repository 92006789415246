<script>

	import { setContext, getContext } from 'svelte';

	const {
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		selected_location_id,
		selected_doctor_id,
		selected_appointment_type_id,
		selected_location,
		selected_doctor,
		selected_appointment_type,
		show_modal,
		root_element,
		component_width,
		loading_slots,
		slot_data,
		all_appointment_types,
		api_working,
		selected_slot_id,
		pagination_index,
		request_appointment_complete,
	} = getContext('schedulerStore');



	import * as util from '@/util'
	import icon_location from '@/lib/icons/location.svelte';
	import icon_clipboard from '@/lib/icons/clipboard.svelte';


	let dom_slots
	
	let show_previous_button = true
	let show_next_button = true;

	$: expanded_slots = []

	let columns = 5

	let dropdown_location_active = false
	let dropdown_doctor_active = false
	let dropdown_appointment_type_active = false

	let default_appointment_type = null

	let doctors_that_support_selected_appointment_type = []

	let keyed_active_slots = {}
	let active_slots = []

	$pagination_index = 0

	let pagination_padding_number = 0

	$: if (($pagination_index || $pagination_index === 0) && max_keyed_day_slots) {

		pagination_padding_number = 5 - (max_keyed_day_slots - $pagination_index);

	}

	let max_keyed_day_slots


	function expandSlot(date) {

		expanded_slots.push(date)

		expanded_slots = expanded_slots

	}



/*******************************************************/
/* Selected Location
/*******************************************************/

	$: if ($selected_location_id && $selected_location) {

		updateSelectedLocation()
	}

	function updateSelectedLocation() {

		default_appointment_type = null

		$selected_doctor_id = 'any'

		doctors_that_support_selected_appointment_type = []	

		$all_appointment_types = []

		$selected_appointment_type_id = null

		if ($selected_location.slotsConstrainedByEHRType) {

			// Get appointmentTypes from doctor slots
			for (const doctor of $selected_location.doctors) {

				for (const slot of doctor.slots) {

					for (const appointmentType of slot.appointmentTypes) {

						$all_appointment_types.push(appointmentType)

					}

				}

			}

		} else {

			$all_appointment_types = $selected_location.appointmentTypes

			if ($all_appointment_types.length > 1) {

				let any_appointment_type = {
					"appointmentTypeId": 'any',
					"appointmentTypeName": "Appointment Type",
					"appointmentTypeLabel": "Appointment Type",
					"isDefault": false
				}

				$all_appointment_types.unshift(any_appointment_type)

			}
			
		}

		
		// Make appointment types unique
		$all_appointment_types = $all_appointment_types.filter((obj, index, self) => index === self.findIndex((t) => (t.appointmentTypeId === obj.appointmentTypeId)))

		default_appointment_type = $all_appointment_types.find(item => item.isDefault)


		if (default_appointment_type) {

			$selected_appointment_type_id = default_appointment_type.appointmentTypeId
			
		}
		
		//slotsConstrainedByEHRType = true
		if ($selected_location?.slotsConstrainedByEHRType) {

			let first_comprehensive_appointment_type = $all_appointment_types.find(item => item.appointmentTypeName.includes('Comprehensive'))

			if (first_comprehensive_appointment_type) {

				$selected_appointment_type_id = first_comprehensive_appointment_type.appointmentTypeId

			} else {
				// Just select first appointment type

				$selected_appointment_type_id = $all_appointment_types[0].appointmentTypeId

			}
					
						
		} else if (! default_appointment_type) {

			$selected_appointment_type_id = $all_appointment_types[0].appointmentTypeId

		}
		

		if (doctors_that_support_selected_appointment_type.length == 1) {

			$selected_doctor_id = doctors_that_support_selected_appointment_type[0]?.doctorOesId
			
		}
		
		

	}


/*******************************************************/
/* Selected Appointment Type
/*******************************************************/

	$: if ($selected_appointment_type_id) {
		updatedSelectAppointmentType()
	}

	function updatedSelectAppointmentType() {

		if (! $selected_appointment_type_id) return

		doctors_that_support_selected_appointment_type = []

		if ($selected_location?.slotsConstrainedByEHRType) {

			for (let doctor of $selected_location.doctors) {

				for (let slot of doctor.slots) {
				
					for (let appointment_type of slot.appointmentTypes) {

						if (appointment_type.appointmentTypeId == $selected_appointment_type.appointmentTypeId && ! doctors_that_support_selected_appointment_type.find(item => item.doctorOesId == doctor.doctorOesId)) {

							doctors_that_support_selected_appointment_type.push(doctor)

						}

					}

				}

			}

		} else {

			doctors_that_support_selected_appointment_type = $selected_location.doctors

		}
					
	}


/*******************************************************/
/* Setting active slots
/*******************************************************/

	$: if ($selected_location_id && $selected_doctor_id && $selected_appointment_type_id) {
		setActiveSlots()
	}
	

	function setActiveSlots() {
				
		active_slots = []
		keyed_active_slots = {}

		if ($selected_doctor_id == 'any') {

			for (let doctor of $selected_location.doctors) {

				// Ensure only a single slot for the same time is pushed as multiple slots from the same time may be available
				doctor.slots.forEach(doctor_slot_item => {
					if (!active_slots.some(active_slots_item => active_slots_item.slotDateTime === doctor_slot_item.slotDateTime)) {
						active_slots.push(doctor_slot_item);
					}
				});

			}			 

		} else {

			active_slots = $selected_doctor.slots

		}

		active_slots = active_slots

		let active_slots_filtered_by_appointment_type = []

		for (let slot of active_slots) {

			// Slot has predefined appointmentTypes, ensure it matches selected_appointment_type
			if (slot.appointmentTypes) {

				if (slot.appointmentTypes.find(item => item.appointmentTypeName == $selected_appointment_type.appointmentTypeName)) {

					active_slots_filtered_by_appointment_type.push(slot)

				}

			} else {
				
				active_slots_filtered_by_appointment_type.push(slot)

			}

		}

		active_slots_filtered_by_appointment_type = active_slots_filtered_by_appointment_type.sort((a,b) => (a.slotDateTime > b.slotDateTime) ? 1 : ((b.slotDateTime > a.slotDateTime) ? -1 : 0))


		keyed_active_slots = active_slots_filtered_by_appointment_type.reduce((acc, slot) => {

			const dateKey = slot.slotDateTime.split('T')[0];
			
			if (!acc[dateKey]) {
				acc[dateKey] = [];
			}

			acc[dateKey].push(slot);

			return acc;
		}, {});

		

		$pagination_index = 0

		max_keyed_day_slots = Object.keys(keyed_active_slots).length

		pagination_padding_number = 5 - (max_keyed_day_slots - $pagination_index);	

		//console.log(active_slots)
		//console.log(keyed_active_slots)


	}



/*******************************************************/
/* Select Slot ID
/*******************************************************/

	$: if ($selected_slot_id) {

		selectedSlotId()

	}

	function selectedSlotId() {

		for (const doctor of $selected_location.doctors) {
			const foundSlot = doctor.slots.find(slot => slot.slotId === $selected_slot_id);
			if (foundSlot) {
				$selected_doctor_id = doctor.doctorOesId;
				break;
			}
		}

		$show_modal = true

		document.body.classList.add('scheduler-modal-active');


		setTimeout(() => {
			document.querySelector('.scheduler-modal__close').focus()
		}, 100);


	}



/*******************************************************/
/* Pagination
/*******************************************************/

	$: if ($pagination_index || $pagination_index === 0) {

		if ($pagination_index > 0) {
			show_previous_button = true
		} else {
			show_previous_button = false
		}

		if ($pagination_index < (max_keyed_day_slots - 1 - columns)) {
			show_next_button = true
		} else {
			show_next_button = false
		}


	}


/*******************************************************/
/* Previous/Next pagination buttons
/*******************************************************/

	function paginate(direction) {

		if (direction == 'previous') {
			
			if ($pagination_index - columns > 0) {
				$pagination_index = $pagination_index - columns
			} else {
				$pagination_index = 0
			}
			
		}	 

		if (direction == 'next') {
			
			if ($pagination_index + columns < (max_keyed_day_slots)) {
				$pagination_index = $pagination_index + columns
			} else {
				$pagination_index = max_keyed_day_slots - 1 - columns
			}

		}

		expanded_slots = []

	}

	
	function getPaddedDay(index, date_format) {
			
		let keys = Object.keys(keyed_active_slots);
		let lastKey = keys[keys.length - 1];
		let last_object = keyed_active_slots[lastKey];

		let last_day
		if (last_object) {
			last_day = last_object[0].slotDateTime;
		} else {
			// Just generate today's date
			last_day = new Date().toISOString().slice(0, 19);
		}

		let date = new Date(last_day);
		date.setDate(date.getDate() + index + 1);

		if (date_format == 'short-weekday') return date.toLocaleDateString('en-US', { weekday: 'short' });
		if (date_format == 'month-day') return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric'  });
		
	}



	function selectAppointmentSlot(slotId) {

		$selected_slot_id = slotId

	}


	function formatDate(dateString, type) {

		const date = new Date(dateString);
	
		if (type == 'short-weekday') return date.toLocaleDateString('en-US', { weekday: 'short' });
		if (type == 'long-weekday') return date.toLocaleDateString('en-US', { weekday: 'long' });
		if (type == 'month-day') return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric'	});
		if (type == 'month-year') return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
				
	}


	function convertToTime(dateString) {

		const date = new Date(dateString);

		let hours = date.getHours();
		const minutes = date.getMinutes();

		const suffix = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12;

		const paddedMinutes = minutes.toString().padStart(2, '0');

		return `${hours}:${paddedMinutes} ${suffix}`;

	}



</script>


{#if $slot_data && $slot_data?.locations?.length && $slot_data?.doctors?.length && $slot_data?.slots?.length}


	<div class="booking-options {$slot_data.locations.length == 1 ? 'booking-options--single-location' : ''}">

	<!---------------------------------------------------------------------------------
	Location Dropdown
	----------------------------------------------------------------------------------->


		{#if $slot_data.locations.length > 1 && ! $prop_location_id}

			<div class="booking-option booking-option--location"
				class:booking-option--single={$slot_data.locations.length == 1}
				class:booking-option--open={dropdown_location_active}
				on:click="{() => {
					dropdown_location_active = ! dropdown_location_active;
					util.handleAnalyticEvent('gsp_location_select_option_'+(dropdown_location_active ? 'open' : 'close')) 
				}}"
				on:mouseleave={() => dropdown_location_active = false}
				id="booking-option--location"
				data-location-id={$selected_location_id}
			>

				{#if ! $selected_location}
					<button class="booking-option__button" type="button" aria-label="Select Location">
						<div class="booking-option__button-image">
							<svelte:component this={icon_location} />
						</div>
						<div class="booking-option__button-label">
							Select a Location
						</div>
					</button>
				{:else}
					<button class="booking-option__button" type="button" aria-label="Select Location">
						<div class="booking-option__button-image">
							<svelte:component this={icon_location} />
						</div>
						<div class="booking-option__button-label">
							{$selected_location.locationName}<!-- ({selected_location.locationSamuraiId}) -->
						</div>
					</button>
				{/if}


				<div class="booking-option__dropdown">

					{#each $slot_data.locations as location}
					
						<button class="booking-option__button" type="button" aria-label={'Select '+location.locationName} on:click="{() => {
								$selected_location_id = location.locationSamuraiId;
								util.handleAnalyticEvent('gsp_location_select_option') 
							}}">
							<div class="booking-option__button-image">
								<svelte:component this={icon_location} />
							</div>
							<div class="booking-option__button-label">
								{location.locationName}<!-- ({location.locationSamuraiId}) -->
							</div>
							
						</button>

					{/each}

				</div>

			</div>
		{/if}



		{#if $selected_location}

	<!---------------------------------------------------------------------------------
	Appointment Type Dropdown
	----------------------------------------------------------------------------------->

			<div class="booking-option booking-option--appointment-type"
				class:booking-option--single={$all_appointment_types.length == 1}
				class:booking-option--open={dropdown_appointment_type_active}
				on:click="{() => {
					dropdown_appointment_type_active = !dropdown_appointment_type_active;
					util.handleAnalyticEvent('gsp_appt_type_select_option_'+(dropdown_appointment_type_active ? 'open' : 'close'))
				}}"
				on:mouseleave={() => dropdown_appointment_type_active = false}
				id="booking-option--appointment-type"
				data-appointment-type-id={$selected_appointment_type_id}
			>
				

				{#if $selected_appointment_type}

					<button class="booking-option__button" type="button" aria-label={'Select '+$selected_appointment_type.appointmentTypeLabel}>
						<div class="booking-option__button-image">
							<svelte:component this={icon_clipboard} />
						</div>
						<div class="booking-option__button-label">
							{$selected_appointment_type.appointmentTypeLabel || $selected_appointment_type.appointmentTypeName}
						</div>
					</button>

				{:else}

					<button class="booking-option__button" type="button" aria-label="Select Appointment Type">
						<div class="booking-option__button-image">
							<svelte:component this={icon_clipboard} />
						</div>
						<div class="booking-option__button-label">
							Select Appointment Type
						</div>
					</button>

				{/if}


				{#if $all_appointment_types.length > 1}
					<div class="booking-option__dropdown">

						{#each $all_appointment_types as appointment_type}

							{#if $selected_appointment_type != appointment_type}
						
								<button class="booking-option__button" type="button"
									on:click="{() => {
										$selected_appointment_type_id = appointment_type.appointmentTypeId;
										util.handleAnalyticEvent('gsp_appt_type_select_option');
									}}">

									<div class="booking-option__button-image">
										<svelte:component this={icon_clipboard} />
									</div>
									<div class="booking-option__button-label">
										{appointment_type.appointmentTypeLabel || appointment_type.appointmentTypeName}
									</div>
									
								</button>

							{/if}

							
						{/each}

					</div>
				{/if}


			</div>


	<!---------------------------------------------------------------------------------
	Doctor Dropdown
	----------------------------------------------------------------------------------->
			
			{#if $selected_appointment_type}	

				<div class="booking-option booking-option--doctor"
					class:booking-option--single={doctors_that_support_selected_appointment_type.length == 1}
					class:booking-option--open={dropdown_doctor_active}
					on:click="{() => {
						dropdown_doctor_active = !dropdown_doctor_active;
						util.handleAnalyticEvent('gsp_doctor_select_option_'+(dropdown_doctor_active ? 'open' : 'close'))

					}}"
					on:mouseleave={() => dropdown_doctor_active = false}
					id="booking-option--doctor"
					data-doctor-id={$selected_doctor_id}
					>

					{#if ! $selected_doctor_id || $selected_doctor_id == 'any'}
						<button class="booking-option__button" type="button" aria-label="Select Any Eye Doctor">
							<div class="booking-option__button-image">
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
							</div>
							<div class="booking-option__button-label">
								Any Eye Doctor
							</div>
						</button>
					{:else if $selected_doctor}

						<button class="booking-option__button" type="button">
							<div class="booking-option__button-image">
								{#if $selected_doctor.doctorPictureUrl}
									<img src={$selected_doctor.doctorPictureUrl} alt={'Photograph of '+$selected_doctor.doctorName}>
								{:else}
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
								{/if}
							</div>
							<div class="booking-option__button-label">
								Dr. {$selected_doctor.doctorName}
							</div>
						</button>
					{/if}


					{#if doctors_that_support_selected_appointment_type.length > 1}
						<div class="booking-option__dropdown">

							{#if $selected_doctor_id != 'any'}
								<button class="booking-option__button " type="button" aria-label="Select Any Eye Doctor"
									on:click="{() => {
										$selected_doctor_id = 'any';
										util.handleAnalyticEvent('gsp_doctor_select_option');
									}}">
									<div class="booking-option__button-image">
										<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
									</div>
									<div class="booking-option__button-label">
										Any Eye Doctor
									</div>
								</button>
							{/if}
							

							{#each $selected_location.doctors as doctor}
								
								{#if $selected_doctor_id != doctor?.doctorOesId}
									<button class="booking-option__button" type="button" aria-label={'Select '+doctor.doctorName} on:click="{() => {
											$selected_doctor_id = doctor.doctorOesId;
											util.handleAnalyticEvent('gsp_doctor_select_option');
										}}">
										<div class="booking-option__button-image">

											{#if doctor.doctorPictureUrl}
												<img src={doctor.doctorPictureUrl} alt={'Photograph of '+doctor.doctorName}>
											{:else}
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
											{/if}

										</div>
										<div class="booking-option__button-label">
											Dr. {doctor.doctorName}
										</div>
									</button>
								{/if}
							
							{/each}

						</div>
					{/if}

				</div>

			{/if}			 

		{/if}

		
	</div>

{/if}


<!---------------------------------------------------------------------------------
Slots
----------------------------------------------------------------------------------->


{#if $selected_location_id && $selected_doctor_id && $selected_appointment_type_id}

	<div class="book__days" data-is-revolution-ehr={$selected_location.slotsConstrainedByEHRTypee} bind:this={dom_slots}>

		<div class="book__columns">

			{#each Object.entries(keyed_active_slots) as [date, day_slots], index}
				
				<div class="book__column" class:book__column--hide="{index < $pagination_index || index > ($pagination_index + columns - 1)}">

					<div class="book__heading">
						<div class="book__weekday" abbr={formatDate(date+'T12:00:00', 'long-weekday')}>{formatDate(date+'T12:00:00', 'short-weekday')}</div>
						<div class="book__date">{formatDate(date+'T12:00:00', 'month-day')}</div>
					</div>
					<div class="book__times">

						{#if ! expanded_slots.includes(date) }
							
							{#each day_slots.slice(0, 3) as slot}

								<button aria-label={'Book '+convertToTime(slot.slotDateTime)} class="book__time gsp-track" data-slot-id={slot.slotId} on:click|preventDefault={() => {
									selectAppointmentSlot(slot.slotId);
									util.handleAnalyticEvent('gsp_selected_time');
								}}>{convertToTime(slot.slotDateTime)}</button>
								
							{/each}

							{#if day_slots.length == 4}
							
								<button aria-label={'Book '+convertToTime(day_slots[3].slotDateTime)} class="book__time gsp-track" data-slot-id={day_slots[3].slotId} on:click|preventDefault={() => {
										selectAppointmentSlot(day_slots[3].slotId);
										util.handleAnalyticEvent('gsp_selected_time');
									}}>{convertToTime(day_slots[3].slotDateTime)}</button>

							{/if}

							{#if day_slots.length > 4}
								<button type="button" aria-label="Show More Bookings" class="book__time book__more book__more-desktop gsp-track" on:click="{() => expandSlot(date)}">More +</button>
								<button type="button" aria-label="Show More Bookings" class="book__time book__more book__more-mobile gsp-track" on:click="{() => expandSlot(date)}">More +</button>
							{/if}

						{:else}

							{#each day_slots as slot}
								<button href="#" aria-label={'Book '+convertToTime(slot.slotDateTime)} class="book__time gsp-track" data-slot-id={slot.slotId} on:click|preventDefault={() => {	
										selectAppointmentSlot(slot.slotId);
										util.handleAnalyticEvent('gsp_more_times_select_click');
								}}>{convertToTime(slot.slotDateTime)}</button>
							{/each}

						{/if}
						
					</div>
				</div>

			{/each}


			{#if pagination_padding_number > 0}
				{#each {length: pagination_padding_number} as _, i}
					<div class="book__column book__column--no-slots">
						<div class="book__heading">
							<div class="book__weekday">{getPaddedDay(i, 'short-weekday')}</div>
							<div class="book__date">{getPaddedDay(i, 'month-day')}</div>
						</div>
						<div class="book__times">
							<a class="book__time" href={'tel:'+$selected_location.phone}>Call Us</a>
						</div>
					</div>
				{/each}
			{/if}

			

		</div>




	<!---------------------------------------------------------------------------------
	Footer
	----------------------------------------------------------------------------------->

		<div class="book__footer-actions">

			<div class="book__footer-actions-start">
				
				{#if show_previous_button}
					<button type="button" class="pagination-button pagination-button--previous gsp-track" id="nav-previous" aria-label="Navigate to previous week" on:click="{() => {
						paginate('previous');
						util.handleAnalyticEvent('gsp_previous_page_btn_clk');
					}}"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"/></svg><span class="label">Previous</span></button>
				{/if}
			
			</div>
			

			<div class="book__footer-actions-middle">
				<a class="button" href={'tel:'+$selected_location.phone}>Call Us</a>
			</div>

			
			<div class="book__footer-actions-end">											

				{#if show_next_button}
					<button type="button" class="pagination-button pagination-button--next gsp-track" id="nav-next" aria-label="Navigate to next week" on:click="{() => {
						paginate('next');
						util.handleAnalyticEvent('gsp_next_page_btn_clk');
					}}"><span class="label">Next</span><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.41z"/></svg></button>
				{/if}
				
			</div>
			

		</div>


	</div>			

{/if}