<script>
	
	import { onMount, onDestroy, setContext, getContext } from 'svelte';

	const {
		api_working,
		form_schema,
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		request_appointment_complete,
		submitted_form_data,
		selected_location_id,
		selected_doctor_id,
		selected_appointment_type_id,
		selected_location,
		selected_doctor,
		selected_appointment_type,
		selected_slot_id,
		pagination_index,
		form_submissions,
		prop_success_redirect_url,
	} = getContext('schedulerStore');

	const store = getContext('schedulerStore');


	import * as util from '@/util'
	import Fields from './Fields.svelte';

	import * as prebuilt_form_schema from '@/lib/form_schema.js'

	$form_schema = prebuilt_form_schema.default_schema

	if ($prop_account_id == 107) $form_schema = prebuilt_form_schema.account_107_schema
	if ($prop_account_id == 1960) $form_schema = prebuilt_form_schema.account_1960_schema
	if ($prop_account_id == 2060) $form_schema = prebuilt_form_schema.account_2060_schema
	if ($prop_account_id == 2332) $form_schema = prebuilt_form_schema.account_2332_schema
	if ($prop_account_id == 2444) $form_schema = prebuilt_form_schema.account_2444_schema
	if ($prop_account_id == 2696) $form_schema = prebuilt_form_schema.account_2696_schema
	if ($prop_account_id == 2807) $form_schema = prebuilt_form_schema.account_2807_schema
	if ($prop_account_id == 3360) $form_schema = prebuilt_form_schema.account_3360_schema
	if ($prop_account_id == 3709) $form_schema = prebuilt_form_schema.account_3709_schema
	if ($prop_account_id == 4450) $form_schema = prebuilt_form_schema.account_4450_schema
	if ($prop_account_id == 506) $form_schema = prebuilt_form_schema.account_506_schema
	if ($prop_account_id == 506) $form_schema = prebuilt_form_schema.account_foxfire_schema
	if ($selected_location?.ehrType == 'ff') $form_schema = prebuilt_form_schema.account_foxfire_schema



	let fieldsRef;
	let errors = {};

	let server_errors = null

	let urlParams = new URLSearchParams(window.location.search);

	let rwg_token = urlParams.get('rwg_token')

	setTimeout(() => {
		let token_field = document.querySelector('.tokens')
		if (token_field) token_field.value = JSON.stringify({rwg_token: rwg_token})
	}, 100);

	async function requestAppointment(event) {

		try {

			event.preventDefault();

			errors = {}; 

			const newErrors = fieldsRef.validate();

			if (Object.keys(newErrors).length > 0) {
				errors = newErrors;
				console.log('Form has errors:', errors);
				
				const firstErrorFieldId = Object.keys(errors)[0];

				const el = document.getElementById(`field-${firstErrorFieldId}`);

				if (el) {
					el.scrollIntoView({ behavior: 'smooth', block: 'center' });
					el.querySelector('select')?.focus();
					el.querySelector('input')?.focus();
					el.querySelector('textarea')?.focus();
				}

				return;
			}

			console.log('Submit values:', $submitted_form_data);

			util.handleAnalyticEvent('gsp_book_btn_click');

			server_errors = null

			$api_working = true

			if ($submitted_form_data.ins_id_ssid != null) $submitted_form_data.ins_id_ssid = String($submitted_form_data.ins_id_ssid);
			if ($submitted_form_data.ins_policy_id != null) $submitted_form_data.ins_policy_id = String($submitted_form_data.ins_policy_id);


			let original_pagination_index = $pagination_index

			$submitted_form_data.appointment_slot_id = $selected_slot_id
			$submitted_form_data.appointment_type_id = $selected_appointment_type_id
			$submitted_form_data.doctor_id = $selected_doctor_id

			console.log($submitted_form_data)
			
			let response = await fetch(`${import.meta.env.VITE_POST_DOMAIN}/api/widget/appointment/submit`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify($submitted_form_data),
			});

			console.log(response)
						
			if (response.status == 200) {

				const data = await response.json()

				

				util.getSlotData(store)

				if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
				    if (dataLayer) dataLayer.push({'event': 'gsp-scheduler-booking-success'});
				} 

				if ($prop_success_redirect_url) {
					window.open($prop_success_redirect_url, '_self');
				} 

				$form_submissions.push(structuredClone($submitted_form_data))

				$form_submissions = $form_submissions			

				setTimeout(() => {
					$pagination_index = original_pagination_index
				}, 100);

				$submitted_form_data = {
					...($submitted_form_data?.ins_benefits && { ins_benefits: $submitted_form_data.ins_benefits }),
					...($submitted_form_data?.ins_holder_birthday && { ins_holder_birthday: $submitted_form_data.ins_holder_birthday }),
					...($submitted_form_data?.ins_employer_company && { ins_employer_company: $submitted_form_data.ins_employer_company }),
					...($submitted_form_data?.ins_holder_name && { ins_holder_name: $submitted_form_data.ins_holder_name }),
					...($submitted_form_data?.ins_policy_id && { ins_policy_id: $submitted_form_data.ins_policy_id }),
					...($submitted_form_data?.ins_id_ssid && { ins_id_ssid: $submitted_form_data.ins_id_ssid }),
				}

				$selected_slot_id = null

				$selected_appointment_type_id = null
				
				$selected_doctor_id = null

				$request_appointment_complete = true
				

			} else {

				const data = await response.json()
				
				console.log(data)

				server_errors = ''

				for (var error of data) {

					server_errors+= error+'<br>'

				}

			}
					
			

		} catch(error) {

			console.log(error)
			

		} finally {

			$api_working = false				

		}


	}



</script>

<form on:submit={requestAppointment}>

	<input type="hidden" id="tokens" class="tokens" name="tokens">

	<Fields
		bind:this={fieldsRef}
		fields={$form_schema.fields}
		{errors}
	/>

	{#if server_errors}
		<div style="text-align:center;margin:20px 0">
			<p style="color:red">Sorry! We encountered an error:<br>			
				{@html server_errors}
			</p>
		</div>
	{/if}


	<div class="form-actions">
		
		<button type="submit" class="button button--primary gsp-track" disabled={$api_working}>
			<span class="button__label">Book</span>
		</button>

	</div>

</form>