<script>

	import { onMount, onDestroy, setContext, getContext } from 'svelte';

	const {
		submitted_form_data,
	} = getContext('schedulerStore');

	import Textfield from './Textfield.svelte';
	import Textarea from './Textarea.svelte';
	import Telephone from './Telephone.svelte';
	import Email from './Email.svelte';
	import Date from './Date.svelte';
	import ButtonGroup from './ButtonGroup.svelte';
	import AppointmentType from './AppointmentType.svelte';
	import Number from './Number.svelte';
	import HealthCardNumber from './HealthCardNumber.svelte';
	import Select from './Select.svelte';

	import Fields from './Fields.svelte';

	export let fields;
	export let errors = {};


	const fieldComponents = {
		textfield: Textfield,
		textarea: Textarea,
		telephone: Telephone,
		email: Email,
		date: Date,
		buttongroup: ButtonGroup,
		appointmenttype: AppointmentType,
		number: Number,
		healthcardnumber: HealthCardNumber,
		select: Select,
	};

	let fieldRefs = {};
	let nestedFieldRefs = {};
	let fieldDomRefs = {};

	let visible_fields = []
	$: if ($submitted_form_data) setVisibleFields()
	
	
	function setVisibleFields() {
		visible_fields = fields.map(field => ({
			field_id: field.field_id,
			isVisible: isVisible(field)
		}));

		// Remove keys from $submitted_form_data for fields that are now hidden
		for (const field of fields) {
			if (!isVisible(field)) {
				delete $submitted_form_data[field.field_id];
			}
		}

		// Recursively apply the same logic to groups
		for (const field of fields) {
			if (field.type === 'group' && field.fields) {
				field.fields.forEach(nested => {
					if (!isVisible(nested)) {
						delete $submitted_form_data[nested.field_id];
					}
				});
			}
		}
	}

	function isVisible(field) {

		if (!field.condition) return true;

		const val = $submitted_form_data[field.condition.field_id];
		
		switch (field.condition.operator) {
			case 'equals':
				return val === field.condition.value;
			case 'not_empty':
				return val !== '' && val != null;
			default:
				return false;
		}
	}

	export function validate() {
		errors = {};

		for (const field of fields) {
			if (field.type === 'group') {
				const nestedRef = nestedFieldRefs[field.field_id];
				if (nestedRef?.validate) {
					const nestedErrors = nestedRef.validate();
					Object.assign(errors, nestedErrors);
				}
				continue;
			}

			if (isVisible(field, $submitted_form_data)) {
				const ref = fieldRefs[field.field_id];
				if (ref?.validate) {
					const result = ref.validate();
					if (result) {
						errors[field.field_id] = result;
					}
				} else if (field.required) {
					const val = $submitted_form_data[field.field_id];
					if (val == null || val === '') {
						errors[field.field_id] = `${field.label || 'This field'} is required`;
					}
				}
			}
		}

		return errors;
	}


</script>

{#each fields as field (field.field_id)}
	
	{#if field.type === 'group'}
		
		<div id={`group-${field.field_id}`} class={`fields-grid fields-grid--${field.grid_columns}`}>
			<Fields
				bind:this={nestedFieldRefs[field.field_id]}
				fields={field.fields}
				{errors}
			/>
		</div>

	{:else if fieldComponents[field.type] && visible_fields.find(f => f.field_id === field.field_id)?.isVisible}
		
		<svelte:component
			this={fieldComponents[field.type]}
			bind:this={fieldRefs[field.field_id]}
			field={field}
			bind:value={$submitted_form_data[field.field_id]}
			is_invalid={errors[field.field_id]}
			ref={fieldDomRefs[field.field_id]}
		/>

	{:else if !fieldComponents[field.type]}
		<p style="color:red;">Unknown field type: {field.type}</p>
	{/if}
	
{/each}
