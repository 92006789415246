import { get } from 'svelte/store';

let store = null;

export function setStore(passed_store) {
	store = passed_store;
}


export async function getSlotData() {
	
	const {
		prop_account_id,
		prop_location_id,
		prop_doctor_id,
		show_modal,
		root_element,
		component_width,
		loading_slots,
		slot_data,
		all_appointment_types,
		api_working,
		selected_location_id,
		submitted_form_data,
		request_appointment_complete,
	} = store;


	// Now use `get(store)` instead of $store
	const account_id = get(prop_account_id);
	if (!account_id) {
		loading_slots.set(false);
		return;
	}

	prop_account_id.set(Number(account_id));
	api_working.set(true);

	try {

		const response = await fetch(`${import.meta.env.VITE_FETCH_DOMAIN}/SchedulerWidget/WidgetSlots/${get(prop_account_id)}`);
		const data = await response.json();

		//console.log(data);

		slot_data.set(data);

	} catch (error) {
		console.log('Error fetching data');
		loading_slots.set(false);
		return;
	} finally {
		api_working.set(false);
	}

	const _slot_data = get(slot_data);

	if (!_slot_data?.doctors?.length) {
		loading_slots.set(true);
		console.log('No doctor data');
		return;
	}
	if (!_slot_data?.locations.length) {
		loading_slots.set(false);
		console.log('No location data');
		return;
	}


	if (get(prop_doctor_id)) {

		_slot_data.doctors = _slot_data.doctors.filter(item => item.doctorOesId == get(prop_doctor_id));

		for (let location of _slot_data.locations) {
			location.doctors = location.doctors.filter(item => item.doctorOesId == get(prop_doctor_id));
		}
	}

	for (let location of _slot_data.locations) {
		location.doctors = location.doctors.sort((a, b) => a.order - b.order);
		for (const doctor of location.doctors) {
			for (const slot of doctor.slots) {
				if (!slot.appointmentTypes?.length) {
					slot.appointmentTypes = _slot_data.appointmentTypes;
				}
			}
		}
	}

	if (_slot_data.locations.length === 1) {
		selected_location_id.set(_slot_data.locations[0]?.locationSamuraiId);
	} else if (get(prop_location_id)) {
		selected_location_id.set(get(prop_location_id));
	}

	slot_data.set(_slot_data)


	if (_slot_data?.locations?.length == 1) {
		selectLocation(_slot_data.locations[0].locationSamuraiId)
	}

	// Only one available appointment type, must select it as there will not be a 'any' appointment type
	let _all_appointment_types = get(store.all_appointment_types)
	if(_all_appointment_types?.length == 1) {
		selectAppointmentType(_all_appointment_types[0].appointmentTypeId)
	}

	loading_slots.set(false);

	//console.log(_slot_data)

}



/*******************************************************/
/* Select Location
/*******************************************************/

export function selectLocation(location_id) {

	//console.log('selectLocation()')

	store.selected_location_id.set(location_id)

	selectDoctor('any')

	selectAppointmentType()
	

	/*

	let doctors_that_support_selected_appointment_type = []	

	if (doctors_that_support_selected_appointment_type.length == 1) {

		$selected_doctor_id = doctors_that_support_selected_appointment_type[0]?.doctorOesId
		
	}
	*/


}



/*******************************************************/
/* Select Doctor
/*******************************************************/

export function selectDoctor(doctor_id) {

	if (! doctor_id) return

	//console.log('selectDoctor()')

	store.selected_doctor_id.set(doctor_id);

	// Marlton, New Patient Yearly Exam
	// A new doctor was selected but new doctor does not support the current appointment type, reset
	if (doctor_id != 'any' && ! get(store.doctors_that_support_selected_appointment_type)?.find(item => item.doctorOesId == doctor_id)) {
		selectAppointmentType()
	}
	

	// Set submitted data 
	let _submitted_form_data = get(store.submitted_form_data)
	if (! _submitted_form_data) _submitted_form_data = {}

	_submitted_form_data.doctor_id = doctor_id

	store.submitted_form_data.set(_submitted_form_data)

}


/*******************************************************/
/* Select Appointment Type
/*******************************************************/


export function selectAppointmentType(appointment_type_id) {

	//console.log('selectAppointmentType()', appointment_type_id)

	if (appointment_type_id) {
		
		store.selected_appointment_type_id.set(appointment_type_id)

	} else {

		let _all_appointment_types = get(store.all_appointment_types)

		let default_appointment_type = null

		default_appointment_type = _all_appointment_types.find(item => item.isDefault)

		if (default_appointment_type) {

			store.selected_appointment_type_id.set(default_appointment_type.appointmentTypeId)
			
		} else {

			store.selected_appointment_type_id.set('any')

		}
		
		//slotsConstrainedByEHRType = true
		if (get(store.selected_location)?.slotsConstrainedByEHRType) {

			let first_comprehensive_appointment_type = _all_appointment_types.find(item => item.appointmentTypeName.includes('Comprehensive'))

			if (first_comprehensive_appointment_type) {

				store.selected_appointment_type_id.set(first_comprehensive_appointment_type.appointmentTypeId)

			} else {

				// Just select first appointment type
				store.selected_appointment_type_id.set(_all_appointment_types[0].appointmentTypeId)

			}
					
						
		}

	}


	if (get(store.selected_location)?.slotsConstrainedByEHRType && ! get(store.request_appointment_complete)) {
		store.show_modal.set(false)
	}


	// Set submitted data 
	let _submitted_form_data = get(store.submitted_form_data)
	if (! _submitted_form_data) _submitted_form_data = {}

	_submitted_form_data.appointment_type_id = get(store.selected_appointment_type_id)

	store.submitted_form_data.set(_submitted_form_data)


}


/*******************************************************/
/* Select Slot ID
/*******************************************************/

export function selectAppointmentSlot(slotId) {

	//console.log('selectAppointmentSlot()')

	store.selected_slot_id.set(slotId)

	// Set submitted data 
	let _submitted_form_data = get(store.submitted_form_data)
	if (! _submitted_form_data) _submitted_form_data = {}

	_submitted_form_data.appointment_slot_id = slotId

	store.submitted_form_data.set(_submitted_form_data)


	for (const doctor of get(store.selected_location)?.doctors) {
		const foundSlot = doctor.slots.find(slot => slot.slotId === slotId);
		if (foundSlot) {
			selectDoctor(doctor.doctorOesId);
			break;
		}
	}

	store.show_modal.set(true)

	document.body.classList.add('scheduler-modal-active');


	setTimeout(() => {
		document.querySelector('.scheduler-modal__close').focus()
	}, 100);

}


/*******************************************************/
/* Anlaytics Event
/*******************************************************/

export function handleAnalyticEvent(event) {

	//console.log(event)

	window?.dataLayer?.push({
		event: 'gsp_scheduler_ga4_event',
		ga4_event_gsp_scheduler: {
			name: event,
			//originalEvent: event
		}
	});

	if (event == 'gsp_new_patient_clk' || event == 'gsp_returning_patient_clk') {

		let cookieValue = (event == 'gsp_new_patient_clk' ? 1 : 0);
		let cookieName  = "gsp_p"; 
		let cookiePath  = "/";
		let expirationTime = 2628000 * 1000                       //For example one month in seconds (2628000)
		let date = new Date();                                  //Create javascript date object
		let dateTimeNow = date.getTime();                       //Get current time in milliseconds since 1 january 1970 (Unix time)
		date.setTime(dateTimeNow + expirationTime);             //Set expiration time (Time now + one month)
		expirationTime = date.toUTCString();                //Convert milliseconds to UTC time string
		document.cookie = cookieName+"="+cookieValue+"; expires="+expirationTime+"; path="+cookiePath;  //Set cookie

	}
	

}