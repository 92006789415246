<!-- comment out the following while developing -->

<svelte:options
	customElement={{
		tag: 'gsp-scheduler',
	}}
/>


<script>

	import { scale } from 'svelte/transition';
	import { fade } from 'svelte/transition';
	import Portal from "svelte-portal";
	import { onMount, onDestroy } from 'svelte';
	import { ics } from 'ics'
	import { createEvent} from 'ics';
	import dayjs from 'dayjs'
	import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';


	let formbuilder = {}
	import FormAccountDefault from './forms/Default.svelte'
	import FormAccountFoxFire from './forms/FoxFire.svelte'
	import FormAccount2696 from './forms/Account2696.svelte'
	import FormAccount2332 from './forms/Account2332.svelte'
	import FormAccount1960 from './forms/Account1960.svelte'
	import FormAccount4450 from './forms/Account4450.svelte'
	import FormAccount2444 from './forms/Account2444.svelte'
	import FormAccount506 from './forms/Account506.svelte'
	import FormAccount2060 from './forms/Account2060.svelte'
	import FormAccount3709 from './forms/Account3709.svelte'
	import FormAccount107 from './forms/Account107.svelte'
	import FormAccount2807 from './forms/Account2807.svelte'
	import FormAccount3360 from './forms/Account3360.svelte'
	

	$: if (account_id && selected_location) {

		account_id = Number(account_id)

		if (account_id == 2696) {
			formbuilder = FormAccount2696
		} else if (account_id == 2332) {
			formbuilder = FormAccount2332
		} else if (account_id == 1960) {
			formbuilder = FormAccount1960
		} else if (account_id == 4450) {
			formbuilder = FormAccount4450			
		} else if (account_id == 2444) {
			formbuilder = FormAccount2444
		} else if (account_id == 506) {
			formbuilder = FormAccount506
		} else if (account_id == 2060) {
			formbuilder = FormAccount2060
		} else if (account_id == 3709) {
			formbuilder = FormAccount3709
		} else if (account_id == 107) {
			formbuilder = FormAccount107
		} else if (account_id == 2807) {
			formbuilder = FormAccount2807
		} else if (account_id == 3360) {
			formbuilder = FormAccount3360
		} else if (selected_location?.ehrType == 'ff') {
			formbuilder = FormAccountFoxFire
		} else {
			formbuilder = FormAccountDefault
		}
		
	}

	import icon_location from '@/lib/icons/location.svelte';
	import icon_clipboard from '@/lib/icons/clipboard.svelte';

	const lottie_animation_src = new URL('./assets/done-animation.json', import.meta.url).href

	export let account_id
	export let location_id
	export let doctor_id
	export let success_redirect_url


	export let color_background = '#fff';
	//export let color_text = '#232323';
	export let color_text = 'inherit';
	export let color_light_border = '#e0e0e0';
	export let color_primary_action = '#024e73';

	let loading = true
	const desktop_loading_src = new URL('./assets/skeleton-desktop.svg', import.meta.url).href
	const mobile_loading_src = new URL('./assets/skeleton-mobile.svg', import.meta.url).href


	let api_working = false

	let rootElement

	let slot_data = {}

	let component_width

	let dropdown_location_active = false
	let selected_location = null

	let dropdown_doctor_active = false
	let selected_doctor = ''

	let all_appointment_types = []
	let dropdown_appointment_type_active = false
	let selected_appointment_type = null
	let selected_appointment_slot = null
	let request_appointment_complete = false
	let errors = null

	let active_slots = []
	let keyed_active_slots = {}
	let max_keyed_day_slots = ''

	$: is_current_location_slots_constrained_by_appointment_type = false
	
	let month_dropdown_options = []
	let month_dropdown_selected = 'calendar'

	$: columns = 7

	$: expanded_slots = []

	let show_previous_button = true
	let show_next_button = true;

	let pagination_index = 0

	let pagination_padding_number = 0
	$: if ((pagination_index || pagination_index === 0) && max_keyed_day_slots) {

		pagination_padding_number = 7 - (max_keyed_day_slots - pagination_index);

	}

	let default_appointment_type = null

	let doctors_that_support_selected_appointment_type = []

	let pre_modal_focus = null
	let show_modal = false
	let modal_close_button = null
	
	let schedule_form = {}

	let form_selected_doctor = {}

	let submitted_data = {}

	let required_fields

	let rwg_token

	let invalid_fields = {}

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


	function validatePhone(value) {
		const validFormat = /^[0-9()\s-]+$/.test(value);
		const digitCount = (value.match(/\d/g) || []).length;
		return validFormat && digitCount >= 10;
	}

	function validateHealthCardNumber(value) {
		const cleaned = value.replace(/\D/g, '');
		let sum = 0;
		let shouldDouble = false;

		for (let i = cleaned.length - 1; i >= 0; i--) {
			let digit = parseInt(cleaned[i], 10);

			if (shouldDouble) {
				digit *= 2;
				if (digit > 9) digit -= 9;
			}

			sum += digit;
			shouldDouble = !shouldDouble;
		}

		return sum % 10 === 0;
	}

	function testField(field) {

		if (! required_fields.find(item => item == field)) return

		if (schedule_form[field] != null && schedule_form[field] !== '') {

			invalid_fields[field] = false

			if (field == 'patient_email' && ! emailRegex.test(schedule_form[field])) invalid_fields[field] = true

			if (field == 'patient_phone') {

				if (! validatePhone(schedule_form[field])) invalid_fields[field] = true

			} 

			if (field == 'health_card_number') {

				if (! validateHealthCardNumber(schedule_form[field])) invalid_fields[field] = true

			} 

		} else {
			invalid_fields[field] = true			
		}


	}

	function testFields() {

		invalid_fields = {}

		for (var field of required_fields) {
			testField(field)
		}

	}
	

	function resetForm() {
		
		request_appointment_complete = false
		
		errors = null

		invalid_fields = {}

		schedule_form = {}

		
	}


	onMount(() => {
		getSlotData()
	})

	async function getSlotData() {

		let urlParams = new URLSearchParams(window.location.search);

		rwg_token = urlParams.get('rwg_token')

		//account_id = urlParams.get('account_id') || 135; //Yesnick
		//location_id = urlParams.get('location_id')
		//doctor_id = urlParams.get('doctor_id')
		
		//account_id = 135; //Yesnick
		//account_id = 3404; //Not Revolution EHR
		//account_id = 3404; //Simmons
		//account_id = 1960; // Rev EHR
		//account_id = 135; // NON Rev EHR
		// account_id = 1718; // Seashore
		//account_id = 3843;
		//account_id = 4340; //Dr. Mariam Hermiz
		//account_id = 107
		//account_id = 2444
		//doctor_id = 16820
		//account_id = 4119 //ehrType": "oop"
		//account_id = 860 // Foxfire
		//account_id = 3709
		//account_id = 2696 // Towne Lake Eye Associates
		//account_id = 2060
		//account_id = 2332 // https://www.lindsayoptometry.com/contact-us/appointment-request-form/
		//account_id = 2807 //https://www.premiereyedoctors.com/
		// account_id = 4450 // https://www.eyesitemeridian.com/contact-us/appointment-request-form/

		if (! account_id) {
			loading = false
			return	
		}

		account_id = Number(account_id)


		try {

			api_working = true

			const response = await fetch(`${import.meta.env.VITE_FETCH_DOMAIN}/SchedulerWidget/WidgetSlots/${account_id}`, {
				method: 'GET'
			})
			
			const data = await response.json()

			slot_data = data

		} catch(error) {

			console.log('Error fetching data')
			loading = false
			return

		} finally {

			api_working = false

		}

		if (! slot_data?.doctors?.length) {
			loading = false
			console.log('No doctor data')
			return
		}
		if (! slot_data?.locations.length) {
			loading = false
			console.log('No location data')
			return
		}

		


		// Remove the other doctors
		if (doctor_id) {
			
			slot_data.doctors = slot_data.doctors.filter(item => item.doctorOesId == doctor_id)
			
			for (let location of slot_data.locations) {
				location.doctors = location.doctors.filter(item => item.doctorOesId == doctor_id)
			}
		}



		// Push appointmentTypes to every doctor, work around non-Revolution EHR locations having no appointmentType
		for (let location of slot_data.locations) {

			location.doctors = location.doctors.sort((a, b) => a.order - b.order);

			for (let doctor of location.doctors) {

				for (const slot of doctor.slots) {

					// Revolution EHR doctor
					if (slot.appointmentTypes && slot.appointmentTypes.length) {
						
					// NON Revolution EHR doctor, just push all appointmentTypes
					} else {

						slot.appointmentTypes = slot_data.appointmentTypes

					}

				}

			}

		}


		// Select Starter Location
		if (slot_data.locations.length == 1) {

			selectLocation(slot_data.locations[0])

		} else if (location_id) {

			let location = slot_data.locations.find(item => item.locationSamuraiId == location_id)
		
			if (location) {

				selectLocation(location)

			}

		}

		loading = false

	}




	function selectLocation(location) {

		selected_location = location

		default_appointment_type = null

		selected_doctor = 'any'

		doctors_that_support_selected_appointment_type = []	

		all_appointment_types = []

		selected_appointment_type = null

		if (selected_location.slotsConstrainedByEHRType) {

			is_current_location_slots_constrained_by_appointment_type = true

			// Get appointmentTypes from doctor slots
			for (const doctor of selected_location.doctors) {

				for (const slot of doctor.slots) {

					for (const appointmentType of slot.appointmentTypes) {

						all_appointment_types.push(appointmentType)

					}

				}

			}


		} else {

			is_current_location_slots_constrained_by_appointment_type = false

			all_appointment_types = selected_location.appointmentTypes

			if (all_appointment_types.length > 1) {

				let any_appointment_type = {
					"appointmentTypeId": 0,
					"appointmentTypeName": "Appointment Type",
					"appointmentTypeLabel": "Appointment Type",
					"isDefault": false
				}

				all_appointment_types.unshift(any_appointment_type)

			}
			
		}

		
		// Make appointment types unique
		all_appointment_types = all_appointment_types.filter((obj, index, self) => index === self.findIndex((t) => (t.appointmentTypeId === obj.appointmentTypeId)))

		default_appointment_type = all_appointment_types.find(item => item.isDefault)


		if (default_appointment_type) {
			selectAppointmentType(default_appointment_type)
		}
		
		//slotsConstrainedByEHRType = true
		if (is_current_location_slots_constrained_by_appointment_type) {

			let first_comprehensive_appointment_type = all_appointment_types.find(item => item.appointmentTypeName.includes('Comprehensive'))

			if (first_comprehensive_appointment_type) {
				selectAppointmentType(first_comprehensive_appointment_type)
			} else {
				// Just select first appointment type
				selectAppointmentType(all_appointment_types[0])
			}
					
						
		} else if (! default_appointment_type) {

			selectAppointmentType(all_appointment_types[0])

		}
		

		if (doctors_that_support_selected_appointment_type.length == 1) {
			selectDoctor(doctors_that_support_selected_appointment_type[0])
		}

		
		setActiveSlots()
		
		//selectDoctor(null)

	}


	function selectAppointmentType(appointment_type) {

		if (! appointment_type) return

		selected_appointment_type = appointment_type;

		schedule_form.appointment_type_id = appointment_type.appointmentTypeId

		doctors_that_support_selected_appointment_type = []

		if (is_current_location_slots_constrained_by_appointment_type) {

			for (let doctor of selected_location.doctors) {

				for (let slot of doctor.slots) {
				
					for (let appointment_type of slot.appointmentTypes) {

						if (appointment_type.appointmentTypeId == selected_appointment_type.appointmentTypeId && ! doctors_that_support_selected_appointment_type.find(item => item.doctorOesId == doctor.doctorOesId)) {

							doctors_that_support_selected_appointment_type.push(doctor)

						}

					}

				}

			}

		} else {

			doctors_that_support_selected_appointment_type = selected_location.doctors

		}

		setActiveSlots()
					
	}


	function selectDoctor(doctor) {

		selected_doctor = doctor;

		setActiveSlots()

	}


	function setActiveSlots() {


		//console.log('setActiveSlots() - Location:', selected_location.locationName, '---- Doctor:', (selected_doctor.doctorName || 'any'), '----- AppointmentType:', selected_appointment_type.appointmentTypeName)
		/*
		console.log('is_current_location_slots_constrained_by_appointment_type', is_current_location_slots_constrained_by_appointment_type)
		console.log('selected_location', selected_location)
		console.log('selected_doctor', selected_doctor)
		console.log('selected_appointment_type', selected_appointment_type)
		*/
		
		
		if (! selected_location || ! selected_doctor || ! selected_appointment_type) {
			console.log('cant set active slots')
			return
		}
		
		pagination_index = 0
		active_slots = []
		keyed_active_slots = {}

		if (selected_doctor == 'any') {

			for (let doctor of selected_location.doctors) {

				// Ensure only a single slot for the same time is pushed as multiple slots from the same time may be available
				doctor.slots.forEach(doctor_slot_item => {
					if (!active_slots.some(active_slots_item => active_slots_item.slotDateTime === doctor_slot_item.slotDateTime)) {
						active_slots.push(doctor_slot_item);
					}
				});

			}			 

		} else {

			active_slots = selected_doctor.slots

		}

		active_slots = active_slots

		let active_slots_filtered_by_appointment_type = []

		for (let slot of active_slots) {

			// Slot has predefined appointmentTypes, ensure it matches selected_appointment_type
			if (slot.appointmentTypes) {

				if (slot.appointmentTypes.find(item => item.appointmentTypeName == selected_appointment_type.appointmentTypeName)) {

					active_slots_filtered_by_appointment_type.push(slot)

				}

			} else {
				
				active_slots_filtered_by_appointment_type.push(slot)

			}

		}

		active_slots_filtered_by_appointment_type = active_slots_filtered_by_appointment_type.sort((a,b) => (a.slotDateTime > b.slotDateTime) ? 1 : ((b.slotDateTime > a.slotDateTime) ? -1 : 0))

		keyed_active_slots = keySlotsByDay(active_slots_filtered_by_appointment_type)	 

		//console.log(active_slots)
		//console.log(keyed_active_slots)
		
		max_keyed_day_slots = Object.keys(keyed_active_slots).length

		pagination_padding_number = 7 - (max_keyed_day_slots - pagination_index);	 


		/*******************************************************/
		/* Calendar Dropdown
		/*******************************************************/

		//console.log(active_slots)

		month_dropdown_options = []

		for (const index in active_slots) {
			
			const date = new Date(active_slots[index].slotDateTime);

			let date_string = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })

			if (! month_dropdown_options.includes(date_string)) month_dropdown_options.push(date_string);

			month_dropdown_options = month_dropdown_options.sort((a, b) => {
				const dateA = new Date(a);
				const dateB = new Date(b);
				return dateA - dateB;
			});

			month_dropdown_options = month_dropdown_options

		}

	}



	function convertToTime(dateString) {

		const date = new Date(dateString);

		let hours = date.getHours();
		const minutes = date.getMinutes();

		const suffix = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12;

		const paddedMinutes = minutes.toString().padStart(2, '0');

		return `${hours}:${paddedMinutes} ${suffix}`;

	}

	function formatDate(dateString, type) {

		const date = new Date(dateString);
	
		if (type == 'short-weekday') return date.toLocaleDateString('en-US', { weekday: 'short' });
		if (type == 'long-weekday') return date.toLocaleDateString('en-US', { weekday: 'long' });
		if (type == 'month-day') return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric'	});
		if (type == 'month-year') return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
				
	}

	function expandSlot(date) {

		expanded_slots.push(date)

		expanded_slots = expanded_slots

	}


	function keySlotsByDay(slots) {

		return slots.reduce((acc, slot) => {

			const dateKey = slot.slotDateTime.split('T')[0];
			
			if (!acc[dateKey]) {
				acc[dateKey] = [];
			}

			acc[dateKey].push(slot);

			return acc;
		}, {});

	}


/*******************************************************/
/* Pagination
/*******************************************************/

	$: if (pagination_index || pagination_index === 0) {

		if (pagination_index > 0) {
			show_previous_button = true
		} else {
			show_previous_button = false
		}

		if (pagination_index < (max_keyed_day_slots - 1 - columns)) {
			show_next_button = true
		} else {
			show_next_button = false
		}


	}


/*******************************************************/
/* Previous/Next pagination buttons
/*******************************************************/

	function paginate(direction) {

		if (direction == 'previous') {
			
			if (pagination_index - columns > 0) {
				pagination_index = pagination_index - columns
			} else {
				pagination_index = 0
			}
			
		}	 

		if (direction == 'next') {
			
			if (pagination_index + columns < (max_keyed_day_slots)) {
				pagination_index = pagination_index + columns
			} else {
				pagination_index = max_keyed_day_slots - 1 - columns
			}

		}

		expanded_slots = []

	}


	function updateMonth(event) {

		//console.log(event)

		let date = event.target.value

		const [targetMonthName, targetYear] = date.split(' ');

		const targetDate = new Date(`${targetYear}-${targetMonthName}-01`);
		const targetMonth = targetDate.getMonth();
		const targetYearNumber = targetDate.getFullYear();
		const keys = Object.keys(keyed_active_slots);
		
		for (let i = 0; i < keys.length; i++) {
			const currentDate = new Date(keys[i]);

			if (currentDate.getFullYear() === targetYearNumber && currentDate.getMonth() === targetMonth) {
				pagination_index = i;
				pagination_index = pagination_index
				
				break;
			}
		}

		month_dropdown_selected = 'calendar'
	
	}

	
	function getPaddedDay(index, date_format) {
			
		let keys = Object.keys(keyed_active_slots);
		let lastKey = keys[keys.length - 1];
		let last_object = keyed_active_slots[lastKey];

		let last_day
		if (last_object) {
			last_day = last_object[0].slotDateTime;
		} else {
			// Just generate today's date
			last_day = new Date().toISOString().slice(0, 19);
		}

		let date = new Date(last_day);
		date.setDate(date.getDate() + index + 1);

		if (date_format == 'short-weekday') return date.toLocaleDateString('en-US', { weekday: 'short' });
		if (date_format == 'month-day') return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric'  });
		
	}



	function selectAppointmentSlot(slotId) {

		pre_modal_focus = rootElement.querySelector('[data-slot-id="'+slotId+'"]')

		invalid_fields = {}

		selected_appointment_slot = slotId

		for (const doctor of selected_location.doctors) {
			const foundSlot = doctor.slots.find(slot => slot.slotId === selected_appointment_slot);
			if (foundSlot) {
				form_selected_doctor = doctor;
				break;
			}
		}

		schedule_form.appointment_types = structuredClone(all_appointment_types)

		// Remove default 'Appointment Type' option for non Rev-EHR
		if (! is_current_location_slots_constrained_by_appointment_type) {
			let any_appointment_type_index = schedule_form.appointment_types.findIndex(item => item.appointmentTypeId == 0)
			if (any_appointment_type_index != -1) schedule_form.appointment_types.splice(any_appointment_type_index, 1)
		}

		schedule_form.appointment_type = schedule_form.appointment_types.find(item => item.appointmentTypeId == selected_appointment_type.appointmentTypeId)

		schedule_form.appointment_slot_id = selected_appointment_slot

		// Check if appointment type has been selected (may be default "Appointment Type" which as no value		
		if (schedule_form.appointment_type?.appointmentTypeId) {
			schedule_form.appointment_type_id = schedule_form.appointment_type.appointmentTypeId
		}


		show_modal = true


		document.body.classList.add('booking-modal-active');

		setTimeout(() => {
			let token_field = document.querySelector('.tokens')
			if (token_field) token_field.value = JSON.stringify({rwg_token: rwg_token})
		}, 100);


		setTimeout(() => {
			modal_close_button.focus()
		}, 100);
		

	}


/*******************************************************/
/* Modal
/*******************************************************/

	function updateFormAppointmentType() {
	
		if (is_current_location_slots_constrained_by_appointment_type && schedule_form?.appointment_type?.appointmentTypeId !== selected_appointment_type.appointmentTypeId) {

			selectAppointmentType(schedule_form.appointment_type)

			closeAppointmentPopup()
		
		} else {

			selectAppointmentType(schedule_form.appointment_type)

		}
		

	}

	function closeAppointmentPopup() {

		resetForm()
		
		document.body.classList.remove('booking-modal-active')
		
		show_modal = false

		pre_modal_focus.focus();

	}

	async function requestAppointment() {

		errors = null
		
		testFields()

		if (Object.values(invalid_fields).every(item => item === false)) {	

			function trimObjectValues(obj) {
			    Object.keys(obj).forEach(key => {
			        if (typeof obj[key] === 'string') {
			            obj[key] = obj[key].trim();
			        }
			    });
			    return obj;
			}
		
			//console.log(submitted_data)

			submitted_data['source'] = window.location.href
			submitted_data['rwg_token'] = rwg_token || '',  
			submitted_data['country'] = selected_location.country
			submitted_data['title'] = document.title
			submitted_data['tokens'] = document.querySelector('.booking-modal .tokens').value

			submitted_data = trimObjectValues(submitted_data);
		
			let response


			try {

				api_working = true

				response = await fetch(`${import.meta.env.VITE_POST_DOMAIN}/api/widget/appointment/submit`, {
					method: 'POST',
					headers: {
						'content-type': 'application/json'
					},
					body: JSON.stringify(submitted_data),
				});

				//console.log(response)
							
				if (response.status == 200) {

					const data = await response.json()

					if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {

					    if (dataLayer) dataLayer.push({'event': 'gsp-scheduler-booking-success'});

					} 

					if (success_redirect_url) {
						window.open(success_redirect_url, '_self');
					} else {
						request_appointment_complete = true
					}

				} else {

					const data = await response.json()
					console.log(data)

					errors = ''

					for (var error of data) {

						errors+= error+'<br>'

					}

				}

			} catch(error) {

				console.log(error)
				

			} finally {

				api_working = false				

			}
		

		} else {

			console.log('fail required fields check')
			console.log(invalid_fields)
			

		}

	}

	async function downloadIcal() {

		let date = slot_data.slots.find(item => item.slotId == selected_appointment_slot)?.slotDateTime

		const event = {
			title: `Appointment with ${form_selected_doctor.doctorName}`,
			start: [Number(dayjs(date).format('YYYY')), Number(dayjs(date).format('MM')), Number(dayjs(date).format('DD')), Number(dayjs(date).format('HH')), Number(dayjs(date).format('mm'))],
			duration: { minutes: 60 },
			location: `${selected_location.addressLine1 || ''} ${selected_location.addressLine2 || ''} ${selected_location.city || ''}, ${selected_location.state || ''}`,
			description: `Phone: ${selected_location.phone}`
		 }

		const filename = 'Appointment.ics'
		const file = await new Promise((resolve, reject) => {
			createEvent(event, (error, value) => {
				if (error) {
					reject(error)
				}
				resolve(new File([value], filename, { type: 'text/calendar' }))
			})
		})

		const url = URL.createObjectURL(file);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = filename;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
		URL.revokeObjectURL(url);


	}


	/*******************************************************/
	/* Register Analytics Events
	/*******************************************************/

	function handleAnalyticEvent(event) {

		//console.log(event)

		window?.dataLayer?.push({
			event: 'gsp_scheduler_ga4_event',
			ga4_event_gsp_scheduler: {
				name: event,
				//originalEvent: event
			}
		});


		if (event == 'gsp_new_patient_clk' || event == 'gsp_returning_patient_clk') {

			let cookieValue = (event == 'gsp_new_patient_clk' ? 1 : 0);
			let cookieName  = "gsp_p"; 
			let cookiePath  = "/";
			let expirationTime = 2628000 * 1000                       //For example one month in seconds (2628000)
			let date = new Date();                                  //Create javascript date object
			let dateTimeNow = date.getTime();                       //Get current time in milliseconds since 1 january 1970 (Unix time)
			date.setTime(dateTimeNow + expirationTime);             //Set expiration time (Time now + one month)
			expirationTime = date.toUTCString();                //Convert milliseconds to UTC time string
			document.cookie = cookieName+"="+cookieValue+"; expires="+expirationTime+"; path="+cookiePath;  //Set cookie

		}
		
			

	}


	export function teleport(node, name = 'teleport-container') {
		let teleportContainer = document.getElementById(name);
		teleportContainer?.appendChild(node);
		teleportContainer.focus(); // optional
	}




</script>



<style>

	.error{border:1px solid red;padding:10px 20px;color:red;text-align:center;border-radius:5px}
	.error:not(:last-child){margin-bottom:10px;}

	.loading-animation{transition:.2s all;pointer-events:none}
	.loading-animation:after{content:'';position:absolute;top:0;right:0;bottom:0;left:0;background-image: linear-gradient(90deg, transparent 0, #fff 40px, transparent 80px); background-size: 50%; animation: shine-lines .7s infinite linear;}
	.loading-animation img{width:100%}

	@keyframes shine-lines {0% {background-position: 0} 100%, 100% {background-position: 100%}}

	p{margin:0}
	p:not(:last-child){margin-bottom:1.5em;}

	.booking{color:var(--color_primary_action);text-align:left;position:relative;}
	.booking.booking-modal-active{z-index:10}

	.booking *, .booking *:before, .booking *:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
	.booking {margin:0;color:var(--color_text);font-weight:400;font-size:16px;line-height:1.6;}


	.booking{position:relative;z-index:3;color:var(--color_text)}
	.booking-options{display:flex;position:relative;z-index:2;gap:20px}
	.booking-options.booking-options--single-location{flex-direction:row-reverse;}

	.booking-option{position:relative;z-index:1;font-weight:bold;color:#000;font-size:16px;border:1px solid var(--color_light_border);border-radius:5px;padding:5px 10px 5px 10px;background:#fff;text-decoration:none;background-color:var(--color_background);background-image:url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32' fill='currentColor' height='32' viewBox='0 0 24 24'><path fill='currentColor' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");background-size:24px;background-position:98% 55%;background-repeat:no-repeat;}
	.booking-option--single{background-image:none;cursor:default !important;}
	.booking-option--single *{cursor:default !important}
	.booking-option--location{width:33.33%;z-index:5}
	.booking-option--appointment-type{flex:1;z-index:3}
	.booking-option--doctor{flex:1;z-index:4;} /* This is above appointment type on mobile */

	.booking-option__dropdown{position:absolute;top:calc(100% - 5px);left:-1px;right:-1px;opacity:0;visibility:hidden;background:#fff;border:1px solid var(--color_light_border);border-top:none;border-radius:0 0 5px 5px;}
	.booking-option--open{z-index:6}
	.booking-option--open .booking-option__dropdown{opacity:1;visibility:visible;}

	.booking-option__dropdown > .booking-option__button{padding:10px 10px;user-select:none}
	.booking-option__dropdown > .booking-option__button:hover{background:#f7f9fc}
	.booking-option__dropdown > .booking-option__button:last-child{margin:0}

	.booking-option__button{flex:1;display:flex;align-items:center;position:relative;text-align:left;width:100%;cursor:pointer;padding:0;background:transparent;border:none;margin:0;font-size:inherit;font-family:inherit;color:inherit}
	.booking-option__button-image{width:50px;height:50px;position:relative;margin:0 10px 0 0}
	.booking-option__button-image :global(svg){width:100%;height:100%;color:var(--color_primary_action)}
	.booking-option__button-image img{border-radius:5px;width:100%;height:100%;object-fit:cover}
	.booking-option__button-label{flex:1}

	.book__days{position:relative;}

	.book__columns{display:grid;grid-template-columns:repeat(7, 1fr);grid-gap: 15px;position:relative;margin:20px 0 0 0}

	.book__column{text-align:center;padding:10px 0}
	.book__column--hide{display:none;}


	.book__heading{border-bottom:1px solid var(--color_light_border);margin:0 0 15px 0;padding:0 0 10px 0;}
	.book__weekday{font-weight:bold;line-height:1.2}
	.book__date{line-height:1.2}
	.book__times{display:grid;grid-template-columns:1fr;grid-gap:10px}
	.book__time{border-radius:5px;background:#fff;border:1px solid var(--color_light_border);padding:5px 5px;cursor:pointer;width:100%;font-family:inherit;font-size:inherit;text-decoration:none;min-height:2em;color:inherit;transition:.2s all;color:#000}
	.book__time:hover{border:1px solid var(--color_primary_action);background:#f7f9fc}
	.book__more{}
	.book__more-mobile{display:none}
	.book__column.expanded .book__more{display:none}

	.book__footer-actions{margin:20px 0 0 0;display:flex;gap:10px;justify-content:space-between;align-items:center}
	.book__footer-actions .book__footer-actions-start{min-width:200px;}
	.book__footer-actions .book__footer-actions-middle{flex:1;display:flex;gap:10px;justify-content:center;white-space:nowrap}
	.zzzbook__footer-actions .book__footer-actions-middle .desktop{}
	.book__footer-actions .book__footer-actions-middle .mobile{display:none;}
	.book__footer-actions .book__footer-actions-end{min-width:200px;text-align:right}

	.pagination-button{display:inline-flex;gap:5px;align-items:center;border:1px solid var(--color_light_border);font-family:inherit;background:#fff;font-size:inherit;border-radius:5px;padding:5px 14px;cursor:pointer;font-family:inherit;font-size:inherit;color:#000;text-decoration:none;height:2.5em}
	.pagination-button :global(svg){width:16px;height:16px;}
	.pagination-button:disabled{border-color:#f2f2f2;color:var(--color_light_border);pointer-events:none;}
	
	.month-dropdown{color:#000;padding:5px 35px 5px 15px;border-radius:5px;font-family:inherit;font-size:inherit;border:1px solid var(--color_light_border);background-color:var(--color_background);-webkit-appearance:none;background-image:url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' fill='currentColor' height='20' viewBox='0 0 24 24'><path fill='currentColor' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");background-position:calc(100% - 10px) 55%;background-repeat:no-repeat;height:2.5em}


	.desktop .loading-animation .desktop{display:block}
	.desktop .loading-animation .mobile{display:none}
	.tablet .loading-animation .desktop{display:none;}
	.tablet .loading-animation .mobile{display:block;}
	.mobile .loading-animation .desktop{display:none;}
	.mobile .loading-animation .mobile{display:block;}


	 .mobile .booking-options, .tablet .booking-options{flex-direction:column;gap:5px}
	 .mobile .booking-options.booking-options--single-location, .tablet .booking-options.booking-options--single-location{flex-direction:column-reverse;}
	 .mobile .booking-option, .tablet .booking-option {flex:1}
	 .mobile .booking-option--location, .tablet .booking-option--location{width:100%}
	 .mobile .booking-option--doctor, .tablet .booking-option--doctor{z-index:3;}

	 .tablet .booking-options.booking-options--single-location{flex-direction:row-reverse;gap:20px}

	 .mobile .booking-option--appointment-type{}
	 .mobile .booking-option__button-image{width:42px;height:42px;}
	 .mobile .book__columns{grid-template-columns:1fr;gap:0px;margin:10px 0 0 0}
	 .mobile .book__heading{margin:0 0 5px 0;padding:0;border:none;text-align:left;display:flex;gap:5px}
	 .mobile .book__column{}
	 .mobile .book__column--no-slots{display:none;}
	 .mobile .book__times{display:grid;grid-template-columns:1fr 1fr 1fr 1fr;gap:5px}
	 .mobile .book__time{padding:8px 5px;white-space:nowrap}
	 .mobile .book__more-desktop{display:none}
	 .mobile .book__more-mobile{display:block}
	 .mobile .book__footer-actions .book__footer-actions-start{min-width:50px}
	 .mobile .book__footer-actions .book__footer-actions-middle{width:100%}
	 .zmobile .book__footer-actions .book__footer-actions-middle .desktop{display:none;}
	 .mobile .book__footer-actions .book__footer-actions-middle .mobile{display:flex;width:100%;gap:5px;align-items:center;justify-content:space-around;}
	 .mobile .book__footer-actions .book__footer-actions-middle .mobile :global(svg){width:24px;height:24px;color:var(--color_primary_action)}
	 .mobile .book__footer-actions .book__footer-actions-end{min-width:50px}
	 
	 .mobile .book__footer-actions{}
	 .mobile .pagination-button .label{display:none;}

	 

</style>



<div class="booking" 
	class:booking-modal-active={show_modal}
	bind:this={rootElement}
	class:desktop={component_width >= 800}
	class:tablet={component_width < 800 && component_width > 480}
	class:mobile={component_width <= 480}
	bind:clientWidth={component_width}
	style="
		--color_background: {color_background};
		--color_text: {color_text};
		--color_light_border: {color_light_border};
		--color_primary_action: {color_primary_action};
		--success: #04cb58;
		--danger: #e23807;
	">

	

	{#if loading}
		<div class="loading-animation">
			<img src={desktop_loading_src} alt="Loading..." class="desktop" />
			<img src={mobile_loading_src} alt="Loading..." class="mobile"	/>
		</div>
	{/if}


	{#if ! loading}

		{#if ! account_id}
			<div class="error">No Account ID</div>
		{/if}


		{#if slot_data && slot_data?.locations?.length && slot_data?.doctors?.length && slot_data?.slots?.length}

			<!--
				{#if location_id && ! selected_location}
					<div class="error">
						Location Not Found
					</div>
				{/if}

				{#if doctor_id && ! selected_doctor}
					<div class="error">
						Doctor Not Found
					</div>
				{/if}
			-->

			<div class="booking-options {slot_data.locations.length == 1 ? 'booking-options--single-location' : ''}">

	<!---------------------------------------------------------------------------------
	Location Dropdown
	----------------------------------------------------------------------------------->

	 
				{#if slot_data.locations.length > 1 && ! location_id}

					<div class="booking-option booking-option--location"
						class:booking-option--single={slot_data.locations.length == 1}
						class:booking-option--open={dropdown_location_active}
						on:click="{() => {
							dropdown_location_active = ! dropdown_location_active;
							handleAnalyticEvent('gsp_location_select_option_'+(dropdown_location_active ? 'open' : 'close')) 
						}}"
						on:mouseleave={() => dropdown_location_active = false}
						id="booking-option--location"
						data-location-id={selected_location ? selected_location.locationSamuraiId : ''}
					>

						{#if ! selected_location}
							<button class="booking-option__button" type="button" aria-label="Select Location">
								<div class="booking-option__button-image">
									<svelte:component this={icon_location} />
								</div>
								<div class="booking-option__button-label">
									Select a Location
								</div>
							</button>
						{:else}
							<button class="booking-option__button" type="button" aria-label="Select Location">
								<div class="booking-option__button-image">
									<svelte:component this={icon_location} />
								</div>
								<div class="booking-option__button-label">
									{selected_location.locationName}<!-- ({selected_location.locationSamuraiId}) -->
								</div>
							</button>
						{/if}


						<div class="booking-option__dropdown">

							{#each slot_data.locations as location}
							
								<button class="booking-option__button" type="button" aria-label={'Select '+location.locationName} on:click="{() => {
										selectLocation(location);
										handleAnalyticEvent('gsp_location_select_option') 
									}}">
									<div class="booking-option__button-image">
										<svelte:component this={icon_location} />
									</div>
									<div class="booking-option__button-label">
										{location.locationName}<!-- ({location.locationSamuraiId}) -->
									</div>
									
								</button>

							{/each}

						</div>

					</div>
				{/if}



				{#if selected_location}

	<!---------------------------------------------------------------------------------
	Appointment Type Dropdown
	----------------------------------------------------------------------------------->

					<div class="booking-option booking-option--appointment-type"
						class:booking-option--single={all_appointment_types.length == 1}
						class:booking-option--open={dropdown_appointment_type_active}
						on:click="{() => {
							dropdown_appointment_type_active = !dropdown_appointment_type_active;
							handleAnalyticEvent('gsp_appt_type_select_option_'+(dropdown_appointment_type_active ? 'open' : 'close'))
						}}"
						on:mouseleave={() => dropdown_appointment_type_active = false}
						id="booking-option--appointment-type"
						data-appointment-type-id={selected_appointment_type ? selected_appointment_type.appointmentTypeId : ''}
					>
						

						{#if selected_appointment_type}

							<button class="booking-option__button" type="button" aria-label={'Select '+selected_appointment_type.appointmentTypeLabel}>
								<div class="booking-option__button-image">
									<svelte:component this={icon_clipboard} />
								</div>
								<div class="booking-option__button-label">
									{selected_appointment_type.appointmentTypeLabel || selected_appointment_type.appointmentTypeName}
								</div>
							</button>

						{:else}

							<button class="booking-option__button" type="button" aria-label="Select Appointment Type">
								<div class="booking-option__button-image">
									<svelte:component this={icon_clipboard} />
								</div>
								<div class="booking-option__button-label">
									Select Appointment Type
								</div>
							</button>

						{/if}


						{#if all_appointment_types.length > 1}
							<div class="booking-option__dropdown">

								{#each all_appointment_types as appointment_type}

									{#if selected_appointment_type != appointment_type}
								
										<button class="booking-option__button" type="button"
											on:click="{() => {
												selectAppointmentType(appointment_type);
												handleAnalyticEvent('gsp_appt_type_select_option');
											}}">

											<div class="booking-option__button-image">
												<svelte:component this={icon_clipboard} />
											</div>
											<div class="booking-option__button-label">
												{appointment_type.appointmentTypeLabel || appointment_type.appointmentTypeName}
											</div>
											
										</button>

									{/if}

									
								{/each}

							</div>
						{/if}


					</div>


	<!---------------------------------------------------------------------------------
	Doctor Dropdown
	----------------------------------------------------------------------------------->
					
					{#if selected_appointment_type}	

						<div class="booking-option booking-option--doctor"
							class:booking-option--single={doctors_that_support_selected_appointment_type.length == 1}
							class:booking-option--open={dropdown_doctor_active}
							on:click="{() => {
								dropdown_doctor_active = !dropdown_doctor_active;
								handleAnalyticEvent('gsp_doctor_select_option_'+(dropdown_doctor_active ? 'open' : 'close'))

							}}"
							on:mouseleave={() => dropdown_doctor_active = false}
							id="booking-option--doctor"
							data-doctor-id={selected_doctor ? selected_doctor.doctorOesId : ''}
							>

							{#if ! selected_doctor || selected_doctor == 'any'}
								<button class="booking-option__button" type="button" aria-label="Select Any Eye Doctor">
									<div class="booking-option__button-image">
										<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
									</div>
									<div class="booking-option__button-label">
										Any Eye Doctor
									</div>
								</button>
							{:else}
								<button class="booking-option__button" type="button">
									<div class="booking-option__button-image">
										{#if selected_doctor.doctorPictureUrl}
											<img src={selected_doctor.doctorPictureUrl} alt={'Photograph of '+selected_doctor.doctorName}>
										{:else}
											<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
										{/if}
									</div>
									<div class="booking-option__button-label">
										Dr. {selected_doctor.doctorName}
									</div>
								</button>
							{/if}


							{#if doctors_that_support_selected_appointment_type.length > 1}
								<div class="booking-option__dropdown">

									{#if selected_doctor != 'any'}
										<button class="booking-option__button " type="button" aria-label="Select Any Eye Doctor"
											on:click="{() => {
												selectDoctor('any');
												handleAnalyticEvent('gsp_doctor_select_option');
											}}">
											<div class="booking-option__button-image">
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
											</div>
											<div class="booking-option__button-label">
												Any Eye Doctor
											</div>
										</button>
									{/if}
									

									{#each selected_location.doctors as doctor}
										
										{#if selected_doctor != doctor}
											<button class="booking-option__button" type="button" aria-label={'Select '+doctor.doctorName} on:click="{() => {
													selectDoctor(doctor);
													handleAnalyticEvent('gsp_doctor_select_option');
												}}">
												<div class="booking-option__button-image">

													{#if doctor.doctorPictureUrl}
														<img src={doctor.doctorPictureUrl} alt={'Photograph of '+doctor.doctorName}>
													{:else}
														<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2"/></svg>
													{/if}

												</div>
												<div class="booking-option__button-label">
													Dr. {doctor.doctorName}
												</div>
											</button>
										{/if}
									
									{/each}

								</div>
							{/if}

						</div>

					{/if}			 

				{/if}

				
			</div>


	<!---------------------------------------------------------------------------------
	Slots
	----------------------------------------------------------------------------------->
		

			{#if selected_location && selected_doctor && selected_appointment_type}
			
				<div class="book__days" data-is-revolution-ehr={is_current_location_slots_constrained_by_appointment_type}>

					<div class="book__columns">

						{#each Object.entries(keyed_active_slots) as [date, day_slots], index}
							
							<div class="book__column" class:book__column--hide="{index < pagination_index || index > (pagination_index + columns - 1)}">

								<div class="book__heading">
									<div class="book__weekday" abbr={formatDate(date+'T12:00:00', 'long-weekday')}>{formatDate(date+'T12:00:00', 'short-weekday')}</div>
									<div class="book__date">{formatDate(date+'T12:00:00', 'month-day')}</div>
								</div>
								<div class="book__times">

									{#if ! expanded_slots.includes(date) }
										
										{#each day_slots.slice(0, 3) as slot}

											<button aria-label={'Book '+convertToTime(slot.slotDateTime)} class="book__time gsp-track" data-slot-id={slot.slotId} on:click|preventDefault={() => {
												selectAppointmentSlot(slot.slotId);
												handleAnalyticEvent('gsp_selected_time');
											}}>{convertToTime(slot.slotDateTime)}</button>
											
										{/each}

										{#if day_slots.length == 4}
										
											<button aria-label={'Book '+convertToTime(day_slots[3].slotDateTime)} class="book__time gsp-track" data-slot-id={day_slots[3].slotId} on:click|preventDefault={() => {
													selectAppointmentSlot(day_slots[3].slotId);
													handleAnalyticEvent('gsp_selected_time');
												}}>{convertToTime(day_slots[3].slotDateTime)}</button>

										{/if}

										{#if day_slots.length > 4}
											<button type="button" aria-label="Show More Bookings" class="book__time book__more book__more-desktop gsp-track" on:click="{() => expandSlot(date)}">More +</button>
											<button type="button" aria-label="Show More Bookings" class="book__time book__more book__more-mobile gsp-track" on:click="{() => expandSlot(date)}">More +</button>
										{/if}

									{:else}

										{#each day_slots as slot}
											<button href="#" aria-label={'Book '+convertToTime(slot.slotDateTime)} class="book__time gsp-track" data-slot-id={slot.slotId} on:click|preventDefault={() => {	
													selectAppointmentSlot(slot.slotId);
													handleAnalyticEvent('gsp_more_times_select_click');
											}}>{convertToTime(slot.slotDateTime)}</button>
										{/each}

									{/if}
									
								</div>
							</div>

						{/each}


						{#if pagination_padding_number > 0}
							{#each {length: pagination_padding_number} as _, i}
								<div class="book__column book__column--no-slots">
									<div class="book__heading">
										<div class="book__weekday">{getPaddedDay(i, 'short-weekday')}</div>
										<div class="book__date">{getPaddedDay(i, 'month-day')}</div>
									</div>
									<div class="book__times">
										<a class="book__time" href={'tel:'+selected_location.phone}>Call Us</a>
									</div>
								</div>
							{/each}
						{/if}

						

					</div>


		

	<!---------------------------------------------------------------------------------
	Footer
	----------------------------------------------------------------------------------->

					<div class="book__footer-actions">

						
						<div class="book__footer-actions-start">
							
							<button type="button" class="pagination-button pagination-button--previous gsp-track" id="nav-previous" aria-label="Navigate to previous week" disabled={! show_previous_button} on:click="{() => {
									paginate('previous');
									handleAnalyticEvent('gsp_previous_page_btn_clk');
								}}"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"/></svg><span class="label">Previous</span></button>
						
						</div>
						

						<div class="book__footer-actions-middle">
							

							{#if month_dropdown_options.length > 1}
							<select bind:value={month_dropdown_selected} on:change={updateMonth} class="month-dropdown gsp-track" aria-label="Select month" id="select-month" on:change="{() => {handleAnalyticEvent('gsp_month_fltr_clk')}}">
								
								<option value="calendar">Month</option>

								{#each month_dropdown_options as month}
									<option value={month}>
										{month.replace(new Date().getFullYear(), '')}
									</option>
								{/each}
							</select>
							{/if}

						</div>

						
						<div class="book__footer-actions-end">											

							<button type="button" class="pagination-button pagination-button--next gsp-track" id="nav-next" aria-label="Navigate to next week" disabled={! show_next_button} on:click="{() => {
								paginate('next');
								handleAnalyticEvent('gsp_next_page_btn_clk');
							}}"><span class="label">Next</span><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.41z"/></svg></button>
							
						</div>
						

					</div>


				</div>

			{/if}

		{/if}



	<!---------------------------------------------------------------------------------
	Modal
	----------------------------------------------------------------------------------->

		{#if show_modal}
			
			<Portal target="body">
			
				<div class="booking-modal"

					class:desktop={component_width >= 800}
					class:tablet={component_width < 800 && component_width > 480}
					class:mobile={component_width <= 480}

					style="
						--color_background: {color_background};
						--color_text: {color_text};
						--color_light_border: {color_light_border};
						--color_primary_action: {color_primary_action};
						--success: #04cb58;
						--danger: #e23807;
					">

					<div class="booking-modal__overlay" transition:fade={{ delay: 0, duration: 200 }}></div>

					<div class="booking-modal__container" transition:fade={{ duration: 100, delay: 0, opacity: 0, start: 0.9 }}>
					
						<div class="booking-modal__content">

							<button type="button" on:focus={() => {modal_close_button.focus()}} aria-label="Back to top"></button>

							<a href="#" class="booking-modal__close" aria-label="Close" bind:this={modal_close_button} on:click={() => {
								closeAppointmentPopup();
								handleAnalyticEvent('gsp_scheduler_modal_exit');
							}}>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>
							</a>
							
							<!--
							<div class="booking-modal__heading">
								Schedule an Appointment with<br>
								<span style="color:var(--color_primary_action)">{selected_location.locationName}</span>
							</div>
							-->
							<div class="booking-modal__header">
							
								<div class="booking-preview">

									<h2>									 
										Dr. {form_selected_doctor.doctorName}
									</h2>

									<div class="booking-preview__columns">
									
										<div class="booking-preview__details">								

											<p>
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"/></svg>

												<span>{#if component_width > 480}When: {/if}
												{dayjs(slot_data.slots.find(item => item.slotId == selected_appointment_slot)?.slotDateTime).format('h:mm a, ddd. MMM DD, YYYY')}</span>

											</p>

											<p>
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5"/></svg> 
												<span>{#if component_width > 480}Where: {/if}{selected_location.addressLine1 || ''} {selected_location.addressLine2 || ''} {selected_location.city || ''},	{selected_location.state || ''}</span>
											</p>

											<p> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m1 17h-2v-2h2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25"/></svg>
												<span>
													{#if component_width < 480}
														Questions: <a href={'tel:'+selected_location.phone} style="text-decoration:underline">Call Us</a>
													{:else}
														Questions: Call us at {selected_location.phone}
													{/if}
												</span>

											</p>
				

										</div>

										{#if form_selected_doctor.doctorPictureUrl}
											<div class="booking-preview__photo">
				
												<img src={form_selected_doctor.doctorPictureUrl} alt={'Photograph of '+form_selected_doctor.doctorName}>								

											</div>
										{/if}

									</div>

								</div>

							</div>

							{#if request_appointment_complete}

								<div class="booking-complete">
									
									<div style="width:300px;margin:0 auto">
										<LottiePlayer src={lottie_animation_src} autoplay={true} loop={false} renderer='svg' background='transparent' height={300} width={300} controls={null} controlsLayout={null} />
									</div>
									<div>
										<strong>Your appointment request has been received.</strong><br><br>
										
										<button type="button" class="button button--primary gsp-track" on:click={() => {
											downloadIcal();
											handleAnalyticEvent('gsp_calendar_btn_click');
										}}>
											<span class="button__icon">
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M16.53 11.06L15.47 10l-4.88 4.88l-2.12-2.12l-1.06 1.06L10.59 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H5V8h14z"/></svg>
											</span>
											<span class="button__label">
												Add to Calendar
											</span>
										</button>

									</div>

								</div>
								

							{:else}

								
								{#if selected_location.schedulerNote}
									<div class="scheduler-note">
										{@html selected_location.schedulerNote.replace(/\r\n|\r|\n/g,'<br />')}
									</div>
								{/if}


								<input type="hidden" id="tokens" class="tokens" name="tokens">


								<div class="field field--select field--inset-label" class:no-value={! schedule_form.appointment_type}>
									<div class="field__label">
										<label for="appointment_type">Appointment Type</label><span class="field__required" aria-label="Required field">*</span>
									</div>
									<div class="field__content">
										<select class="field__input field__input--select" id="appointment_type" name="appointment_type" bind:value={schedule_form.appointment_type} on:change={() => {
											updateFormAppointmentType();
											handleAnalyticEvent('gsp_modal_appt_type_clk');
										}} on:blur={(event) => {testField('appointment_type')}}>
											
											<option value={null}>Appointment Type ></option>

											{#each schedule_form.appointment_types as appointment_type}
												<option value={appointment_type}>{appointment_type.appointmentTypeLabel || appointment_type.appointmentTypeName}</option>
											{/each}

										</select>

									</div>
									{#if invalid_fields['appointment_type']}
										<div class="field__required-message"><strong>!</strong> - Appointment Type required</div>
									{/if}
									
								</div>

								

								<div class="fields-grid fields-grid--2" style="display:flex !important">

									<div class="field field--inset-label" style="margin-bottom:0px !important">
										<div class="field__label">
											<label for="patient_first_name">Legal First Name</label><span class="field__required" aria-label="Required field">*</span>
										</div>
										<div class="field__content">
											<input type="text" class="field__input field__input--textfield" id="patient_first_name" name="patient_first_name" bind:value={schedule_form.patient_first_name} on:blur={(event) => {testField('patient_first_name')}} >
										</div>
										{#if invalid_fields['patient_first_name']}
											<div class="field__required-message"><strong>!</strong> - First Name required</div>
										{/if}
									</div>

									<div class="field field--inset-label" style="margin-bottom:0px !important">
										<div class="field__label">
											<label for="patient_last_name">Last Name</label><span class="field__required" aria-label="Required field">*</span>
										</div>
										<div class="field__content">
											<input type="text" class="field__input field__input--textfield" id="patient_last_name" name="patient_last_name" bind:value={schedule_form.patient_last_name} on:blur={(event) => {testField('patient_last_name')}}>
										</div>
										{#if invalid_fields['patient_last_name']}
											<div class="field__required-message"><strong>!</strong> - Last Name required</div>
										{/if}
									</div>

								</div>

								
								
								<svelte:component this={formbuilder}

									selected_location={selected_location}
									invalid_fields={invalid_fields}
									
									bind:schedule_form={schedule_form}
									bind:required_fields={required_fields}
									bind:submitted_data={submitted_data}

									testField={testField}
									handleAnalyticEvent={handleAnalyticEvent}
								>
								</svelte:component>
								




								{#if errors}
									<div style="text-align:center">

										<p style="color:red">Sorry! We encountered an error:<br>
											
											{@html errors}

										</p>

									</div>
								{/if}

								<div class="form-actions">
									
									<button type="button" class="button button--primary gsp-track" on:click={() => {
											requestAppointment();
											handleAnalyticEvent('gsp_book_btn_click');
										}} disabled={api_working}><span class="button__label">Book</span>
									</button>

								</div>

							{/if}

							
							<div class="booking-modal__footer">

								<p>
									
									{#if component_width < 480}
										Any Questions? <a href={'tel:'+selected_location.phone}  style="text-decoration:underline">Call Us</a>
									{:else}
										Questions? Call us at {selected_location.phone}
									{/if}

									<button type="button" on:focus={() => {modal_close_button.focus()}} aria-label="Back to top"></button>
								
								</p>

								
								<!--
								{JSON.stringify(schedule_form)} 
								-->

								
							</div>
							


						</div>

					</div>



					<svelte:element this='style'>
						
						{@html `

							 /*******************************************************/
							 /* Modal
							 /*******************************************************/

							body.booking-modal-active .mfp-wrap, body.booking-modal-active .mfp-bg{display:none !important}

							 .booking-modal{position:fixed;z-index:99999999999;top:0;right:0;bottom:0;left:0;}
							 .booking-modal__overlay{background:rgba(0,0,0,.5);position:fixed;top:0;right:0;bottom:0;left:0}
							 .booking-modal__container{position:fixed;top:50%;left:50%;transform: translate(-50%,-50%);display:flex;width:100%;max-width:800px;background:#fff;border-radius:10px;max-height:100vh;}
							 .booking-modal__content{flex:1;width:100%;overflow:auto;padding:30px;}
							 .booking-modal__close{position:absolute;z-index:2;top:10px;right:10px;cursor:pointer;color:#767676;font-size:0}
							 .booking-modal__heading{font-size:1.5em;line-height:1.2;margin:0 0 20px 0;text-align:center;font-weight:bold}
							 .booking-modal__subheading{font-size:1.2em;line-height:1.1;margin:0 0 20px 0;font-weight:bold}

							.booking-modal .booking-modal__content{padding:20px 30px}

							.booking-modal__header{border-bottom:1px solid var(--color_primary_action);padding:0 30px 10px 30px;margin:0 -30px 30px -30px}
							.booking-modal__footer{border-top:1px solid var(--color_primary_action);padding:20px 30px 0 30px;margin:40px -30px 0 -30px;text-align:center}

							.booking-preview{}
							.booking-preview h2{margin:0 0 15px 0;line-height:1.1;font-size:20px}
							.booking-preview__columns{display:flex;gap:30px;align-items:center}
							.booking-preview__photo{width:15%}
							.booking-preview__photo img{border-radius:10px;}
							.booking-preview__photo img, .booking-preview__photo svg{width:100%;height:auto;color: var(--color_primary_action)}
							.booking-preview__details{flex:1}
							.booking-preview__details p{margin:0 0 .5em 0;display:flex;gap:10px;line-height:1.2;align-items:center}
							.booking-preview__details p span{flex:1}
							.booking-preview__details p svg{width:24px;height:24px;color: var(--color_primary_action)}

							.scheduler-note{margin:0 0 30px 0;}

							.booking-complete{text-align:center}

							 /*******************************************************/
							 /* Form Elements
							 /*******************************************************/

							 .fields-grid{display:grid;grid-template-columns: 1fr;gap:20px;margin-bottom:20px;}
							 .fields-grid--2{grid-template-columns: repeat(2,1fr);}
							 .fields-grid--3{grid-template-columns: repeat(3,1fr);}
							 .fields-grid--4{grid-template-columns: repeat(4,1fr);}
							 .fields-grid--5{grid-template-columns: repeat(5,1fr);}
							 .fields-grid > .field{margin:0;}

							 .fields-grid .fields-grid{gap:10px;margin:0}

							 .field{margin-bottom:20px;position:relative;flex:1}
							 .field:last-child{margin:0;}
							 .field--no-margin{margin:0;}
							 .field--half-margin{margin-bottom:.75em;}
							 .field__label{margin:0 0 8px 0;font-weight:500;line-height:1;}
							 .field__label label{margin:0 4px 0 0;background:none;}
							 .field__label button{position:relative;vertical-align:text-bottom;}
							 
							 .field.field--inset-label{margin-top:6px;}
							 .field.field--inset-label .field__label{background:#fff;position:absolute;top:-6px;left:10px;z-index:2;padding:0 3px;font-size:.8em;transition:.2s all}
							 .field--select.field--inset-label.no-value .field__label{display:none;}
							 .field--select.field--inset-label:focus-within .field__label{top:-6px;left:10px;font-size:.8em}

							 .field--inset-below{font-size:.8em;}

							 .field--horizontal{display:flex;align-items:center}
							 .field--horizontal .field__label{margin:0 1em 0 0;width:20%;line-height:1.2}
							 .field--horizontal .field__input{flex:1;}

							 .field__required{color:red;margin:0 0 0 2px}
							 .field__input{position: relative;}
							 .field__description{font-size:.8em;margin:5px 0 0 0;color:#757575;line-height:1.4}
							 .field__content{position:relative}
							 .field__required-message{font-size:.9em;color:red;margin:2px 0 0 0;}

							 .field__input--textfield, 
							 .field__input--password, 
							 .field__input--email,
							 .field__input--textarea,
							 .field__input--select,
							 .field__input--multiselect,
							 .field__input--date,
							 .field__input--number,
							 .field__input--telephone{width:100%;font-size:inherit;font-family:inherit;padding:.7em .75em .7em .75em !important;border:1px solid var(--color_light_border);border-radius:0;margin:0;display:block;background:#fff;border-radius:4px;box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.01);}
							 .field__input--select{-webkit-appearance:none;padding-right:30px;background-color:#fff;background-image:url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' fill='%23000' height='20' viewBox='0 0 24 24'><path fill='%23666' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");background-position:99% 63%;background-repeat:no-repeat;}
							 .field__input--range{width:100%;margin: 0;padding: 0 !important;}
							 .field__input--date{}
							 .field__input--textfield:disabled, .field__input--textarea:disabled, .field__input--select:disabled{background-color:#fff;color:#666}
							 textarea.field__input:focus,
							 select.field__input:focus,
							 input.field__input:focus{border-color:var(--color_primary_action);outline:3px solid var(--color_primary_action-lighter);}


							.field__input--number::-webkit-outer-spin-button,
							.field__input--number::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
							.field__input--number {-moz-appearance:textfield;}


							 .form-actions{text-align:center;margin:30px 0 0 0}
							 .form-actions .button{min-width:50%}

							 button{font-family:inherit;font-size:inherit;background:none;border:none;margin:0;padding:0}

							 .button{display:inline-flex;align-items:center;justify-content:center;gap:.5em;padding:0 1.5em;line-height:1;white-space:nowrap;zzzvertical-align:middle;margin:0;border-radius:4px;min-height:2.7em;text-decoration:none;cursor:pointer;position:relative;font-family:inherit;color:var(--text);border:1px solid var(--color_primary_action);touch-action:manipulation;transition:.2s all;font-weight:500;background:#fff;}
							 .button:not(.button--link):not(.button--outline):hover{color:#fff;background:var(--color_primary_action)}

							 .button--small{font-size:11px}
							 .button--small .button__icon svg{width:16px;height:16px;}

							 .button__label{font-weight:500;min-height:unset;display:block}

							 .button__icon{vertical-align:middle;display:inline-flex;align-items:center;justify-content:center;border:none;background:none;outline:none;cursor:pointer;}
							 .button__icon svg{color:inherit;width:18px;height:18px;}
							 .button:hover .button__icon{}
							 .button__icon:only-child{margin:0 -.5em;}

							 .button:disabled, .button.button--disabled{border-color:var(--color_light_border);cursor:default;pointer-events:none;background: #efefef;color:#666;}
							 .button.button--primary:disabled, .button--primary.button--disabled{}

							 .button--full-width{width:100%;}
							 .button-group{display:flex;gap:10px;max-width:364px}
							 .button-group .button{flex:1}

							 .button--active{background:#fff;color:#000;}
							 .button--inactive{border-color:var(--color_light_border);}
							 .button--inactive .button__icon{opacity:.5}

							 .button--cancel{border:1px solid var(--color_light_border)}

							 .button--primary{background:var(--color_primary_action);color:#fff;border:1px solid transparent;}
							 .button--primary:hover{background:var(--color_primary_action-darker);color:#fff;border:1px solid transparent}
							 .button--primary .button__icon img, .button--primary .button__icon{}
							 .button--primary:hover .button__icon img, .button--primary:hover .button__icon svg{}

							 .button--tertiary{border:1px solid transparent;}

							 .button--outline{}
							 
							 .button--back{border-color:var(--color_light_border);color:#767676}
							 .button--back:hover{color:#fff;}


							 .notice{background:#fff;padding:15px 25px;margin:0 0 30px 0;border:1px solid var(--primary);border-left:10px solid var(--primary);position:relative}
							 .notice--error{border:1px solid var(--danger);border-left:10px solid var(--danger);}
							 .notice--success{border:1px solid var(--success);border-left:10px solid var(--success);}
							 .notice--muted{border:1px solid var(--border);border-left:10px solid var(--border)}
							 .notice > *:last-child{margin-bottom:0;}
							 .notice h2{font-size:1.3em;margin:0 0 1em 0;}
							 .notice p{margin:0 0 1em 0;}
							 .notice__close{position:absolute !important;top:8px;right:20px;}

							 .insurance{margin-top:35px}


							 @media only screen and (max-width: 480px) {
							 	body.booking-modal-active{position:fixed;}
							 	.booking-modal__container{max-height:unset;max-width:unset;top:0;right:0;bottom:0;left:0;transform:none}
							 	.booking-modal__close{top:10px;right:10px;}
							 	.booking-modal__heading{font-size:1.2em;}
							 	.booking-modal__subheading{font-size:1em;}

							 	.booking-preview{gap:20px;margin:0 0 10px 0}
							 	.booking-preview__details{font-size:.9em}
							 	.booking-preview__photo{width:25%;}

							 	.fields-grid{display:block;}
							 	.fields-grid > .field{margin-bottom:20px;}
							 	.fields-grid--3.fields-grid--date{display:flex}

							 }



							`
						}
					</svelte:element>

				</div>

			</Portal>

		{/if}



	{/if}




</div>